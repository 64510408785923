<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <!-- Global notification live region, render this permanently at the end of the document -->
  <div
    aria-live="assertive"
    class="fixed z-50 inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start overflow-y-auto">
    <div
      class="w-full flex flex-col items-center space-y-4 sm:items-end">
      <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
      <transition-group
        enter-active-class="transform ease-out duration-300 transition"
        enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
        leave-active-class="transform transition ease-in duration-300"
        leave-from-class="translate-y-0 opacity-100 sm:translate-x-0"
        leave-to-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2">
        <div
          v-for="(notification, idx) in $store.getters.notifications"
          :key="notification.id"
          class="max-w-sm w-full bg-white dark:bg-dark-theme-secondary shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
          <div
            class="p-4 border-l-4"
            :class="[notification.colorSet ? notification.colorSet.borderColor : '' ]">
            <div class="flex items-start">
              <div class="flex-shrink-0">
                <component
                  :is="notification.colorSet.icon"
                  v-if="notification.colorSet"
                  class="h-6 w-6 text-gray-400"
                  :class="notification.colorSet.textColor"
                  aria-hidden="true" />
                <CheckCircleIcon
                  v-if="!notification.colorSet"
                  class="h-6 w-6 text-green-400"
                  aria-hidden="true" />
              </div>
              <div class="ml-3 w-0 flex-1 pt-0.5">
                <p class="text-sm font-medium text-gray-900 dark:text-gray-200">
                  {{ notification.title }}
                </p>
                <p class="mt-1 text-sm text-gray-500 dark:text-gray-400">
                  {{ notification.message }}
                </p>
                <div
                  v-if="notification.style === 'inbox'"
                  class="mt-3 flex space-x-7">
                  <button
                    class="rounded-md text-sm font-medium text-theme-blue hover:text-indigo-500 dark:text-gray-200 dark:hover:text-dark-theme-tertiary focus:outline-none"
                    @click="routeToMessageCenter(notification.id, idx)">
                    Read more...
                  </button>
                  <button
                    type="button"
                    class="rounded-md text-sm font-medium text-gray-600 dark:text-gray-300 dark:hover:text-gray-200 hover:text-gray-500 focus:outline-none"
                    @click="closeNotification(notification, idx)">
                    Dismiss
                  </button>
                </div>
              </div>
              <div class="ml-4 flex-shrink-0 flex">
                <button
                  class="bg-white dark:bg-gray-900 rounded-md inline-flex text-gray-400 hover:text-gray-500 dark:hover:text-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  @click="closeNotification(notification, idx)">
                  <span class="sr-only">Close</span>
                  <XIcon
                    class="h-5 w-5"
                    aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition-group>
    </div>
  </div>
</template>

<script>
  import {
    CheckCircleIcon,
  } from '@heroicons/vue/outline';
  import { XIcon } from '@heroicons/vue/solid';

  export default {
    components: {
      CheckCircleIcon,
      XIcon,
    },
    methods: {
      async closeNotification(notification, idx) {
        await this.$store.dispatch('removeNotification', { payload: notification, id: idx });
      },
      async routeToMessageCenter(id, idx) {
        await this.$store.dispatch('removeNotification', { id: idx });
        await this.$store.dispatch('setMessageToReadById', id);
        await this.$router.push({ name: 'Message Center', params: { id } });
      },
    },
  };
</script>
