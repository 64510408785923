<template>
  <form @submit.prevent="updateUser">
    <div class="shadow sm:rounded-md sm:overflow-hidden">
      <div class="bg-white dark:bg-dark-theme-secondary py-6 px-4 space-y-6 sm:p-6">
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300">
            Personal Information
          </h3>
          <p class="mt-1 text-sm text-gray-500 dark:text-gray-300">
            Update your account information.
          </p>
        </div>

        <div class="grid grid-cols-6 gap-6">
          <app-input
            v-model:value="first_name"
            name="first_name"
            title="First name"
            custom-class="col-span-6 sm:col-span-3"
            :errors="errors" />

          <app-input
            v-model:value="last_name"
            name="last_name"
            title="Last name"
            custom-class="col-span-6 sm:col-span-3"
            :errors="errors" />

          <app-input
            v-model:value="email"
            name="email"
            type="email"
            title="Email address"
            custom-class="col-span-6"
            :errors="errors" />
        </div>
      </div>
      <div class="px-4 pb-6 bg-gray-50 dark:bg-dark-theme-secondary text-right sm:px-6">
        <app-button title="Save" />
      </div>
    </div>
  </form>
</template>

<script>
  import axios from 'axios';
  import AppInput from '../../../globalComponents/AppInput.vue';
  import AppButton from '../../../globalComponents/AppButton.vue';

  export default {
    components: {
      AppButton,
      AppInput,
    },
    data() {
      return {
        first_name: this.$store.getters.user.first_name,
        last_name: this.$store.getters.user.last_name,
        email: this.$store.getters.user.email,
        errors: null,
      };
    },
    methods: {
      async updateUser() {
        try {
          await axios({
            method: 'PUT',
            url: 'clients/edit',
            data: {
              id: this.$store.getters.user.id,
              first_name: this.first_name,
              last_name: this.last_name,
              email: this.email,
            },
          });

          await this.$store.dispatch('setNotification', {
            title: 'Successfully updated!',
            message: 'The user has been updated',
          });
        } catch (error) {
          this.errors = JSON.parse(error.request.response);
        }
      },
    },
  };
</script>
