<template>
  <div>
    <h3 class="mt-5 text-lg leading-6 font-medium text-gray-900 dark:text-gray-200">
      {{ text }}
    </h3>
    <h6
      v-if="subtitle"
      class="text-gray-500 text-sm">
      {{ subtitle }}
    </h6>
  </div>
</template>

<script>
  export default {
    props: {
      text: {
        type: String,
        required: true,
      },
      subtitle: {
        type: String,
        default: null,
      },
    },
  };
</script>
