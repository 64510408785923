<template>
  <TransitionRoot
    as="template"
    :show="$store.getters.showMessagesSidebar">
    <Dialog
      as="div"
      class="fixed inset-0 z-20 overflow-hidden"
      @close="closeSidebar">
      <div class="absolute inset-0 overflow-hidden">
        <TransitionChild
          as="template"
          enter="ease-in-out duration-500"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in-out duration-500"
          leave-from="opacity-100"
          leave-to="opacity-0">
          <DialogOverlay class="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>
        <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex">
          <TransitionChild
            as="template"
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enter-from="translate-x-full"
            enter-to="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leave-from="translate-x-0"
            leave-to="translate-x-full">
            <div class="relative w-screen max-w-md">
              <TransitionChild
                as="template"
                enter="ease-in-out duration-500"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in-out duration-500"
                leave-from="opacity-100"
                leave-to="opacity-0">
                <div class="absolute top-0 left-0 -ml-8 pt-4 pr-2 flex sm:-ml-10 sm:pr-4">
                  <button
                    type="button"
                    class="rounded-md text-gray-300 hover:text-white focus:outline-none"
                    @click="closeSidebar">
                    <span class="sr-only">Close panel</span>
                    <XIcon
                      class="h-6 w-6"
                      aria-hidden="true" />
                  </button>
                </div>
              </TransitionChild>
              <div class="h-full flex flex-col py-6 bg-white dark:bg-dark-theme-primary shadow-xl overflow-y-scroll">
                <slot></slot>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
  import {
    Dialog, DialogOverlay, TransitionChild, TransitionRoot,
  } from '@headlessui/vue';
  import { XIcon } from '@heroicons/vue/outline';

  export default {
    components: {
      Dialog,
      DialogOverlay,
      TransitionChild,
      TransitionRoot,
      XIcon,
    },
    methods: {
      closeSidebar() {
        this.$store.dispatch('showMessagesSidebar', false);
      },
    },
  };
</script>
