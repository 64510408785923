const fontSize = 13;
const themeLightColor = '#111827';
const themeDarkColor = '#9ca3af';

export const plugins = {
  plugins: {
    legend: {
      onClick: null,
    },
  },
};

const gridLinesDarkMode = {
  grid: {
    color: '#333333',
  },
};

const ticksLight = {
  color: themeLightColor,
  font: {
    size: fontSize,
  },
};

const ticksDark = {
  color: themeDarkColor,
  font: {
    size: fontSize,
  },
};

export const themeLightOptions = {
  scales: {
    y: {
      ticks: ticksLight,
    },
    x: {
      ticks: ticksLight,
    },
  },
};

export const themeDarkOptions = {
  scales: {
    y: {
      ticks: ticksDark,
      ...gridLinesDarkMode,
    },
    x: {
      ticks: ticksDark,
      ...gridLinesDarkMode,
    },
  },
};

const chartAssetsOptions = {
  responsive: true,
  interaction: {
    mode: 'index',
    intersect: false,
  },
  stacked: false,
};

const chartYScaleOptions = {
  type: 'linear',
  display: true,
  position: 'left',
  title: {
    display: true,
    text: 'Percentage / Quantity',
  },
};

const chartY1ScaleOptions = {
  type: 'linear',
  display: true,
  position: 'right',
  title: {
    display: true,
    text: 'Euro Coin Rate',
  },
  grid: {
    drawOnChartArea: false,
  },
};

export const chartAssetsLightOptions = {
  ...chartAssetsOptions,
  plugins: {
    legend: {
      labels: {
        color: themeLightColor,
      },
    },
  },
  scales: {
    y: {
      ...chartYScaleOptions,
      ticks: ticksLight,
    },
    x: {
      ticks: ticksLight,
    },
    y1: {
      ...chartY1ScaleOptions,
      ticks: ticksLight,
    },
  },
};

export const chartAssetsDarkOptions = {
  ...chartAssetsOptions,
  plugins: {
    legend: {
      labels: {
        color: themeDarkColor,
      },
    },
  },
  scales: {
    y: {
      ...chartYScaleOptions,
      ...gridLinesDarkMode,
      ticks: ticksDark,
    },
    x: {
      ...gridLinesDarkMode,
      ticks: ticksDark,
    },
    y1: {
      ...chartY1ScaleOptions,
      ...gridLinesDarkMode,
      ticks: ticksDark,
    },
  },
};
