<template>
  <div class="border-t border-b border-gray-200 dark:border-dark-theme-primary bg-gray-100 dark:bg-dark-theme-secondary px-6 py-2 text-sm font-medium text-gray-600 dark:text-gray-300">
    <div class="flex border-b border-gray-300 dark:border-gray-900 pb-2">
      <span
        class="flex-1 flex items-center cursor-pointer"
        @click="switchOrder">
        Sorted by date

        <ArrowDownIcon
          v-if="orderBy === 'desc'"
          class="h-3 w-3 ml-1" />
        <ArrowUpIcon
          v-if="orderBy === 'asc'"
          class="h-3 w-3 ml-1" />
      </span>
      <AdjustmentsIcon
        class="h-5 w-5 text-gray-500 dark:text-gray-300 justify-end cursor-pointer"
        aria-hidden="true"
        @click.prevent="showFilters = !showFilters" />
    </div>
    <div class="flex">
      <ul
        role="list"
        class="flex justify-between w-full">
        <li
          v-for="(option, optionIdx) in filters"
          :key="optionIdx"
          class="relative cursor-pointer pt-2 flex items-center outline-none">
          <input
            :id="`${optionIdx}`"
            v-model="option.selected"
            :name="option.name"
            :value="option.selected"
            checked
            type="checkbox"
            class="h-4 w-4 border-gray-300 rounded text-theme-blue dark:text-dark-theme-tertiary focus:ring-0"
            @change="check">
          <label
            :for="`${optionIdx}`"
            class="ml-2 text-sm text-gray-600 dark:text-gray-300 font-medium">
            {{ option.label }}
          </label>
        </li>
      </ul>
    </div>
  </div>
  <div>
    <input
      id="search"
      :value="search"
      type="text"
      name="search"
      placeholder="Search messages"
      class="shadow-sm w-full pr-12 bg-transparent border-t-0 border-r-0 border-l-0 border-gray-200 px-6 text-sm dark:text-gray-200 dark:border-gray-900 dark:focus:ring-dark-theme-tertiary dark:focus:border-dark-theme-tertiary text-sm"
      @input="event => $emit('update:search', event.target.value)">
  </div>
  <div>
    <ul
      v-if="showFilters"
      role="list"
      class="divide-y transform divide-gray-200 border-b border-gray-200 dark:divide-dark-theme-primary dark:divide-dark-theme-primary">
      <li class="relative bg-gray-50 dark:bg-gray-600 py-2 px-6 hover:bg-gray-50 hover:dark:bg-gray-700 cursor-pointer">
        <p
          class="line-clamp-2 text-sm text-gray-600 dark:text-gray-300"
          @click="showUnread">
          <span v-if="!read">Show Unread</span>
          <span v-else>Show All</span>
        </p>
      </li>
      <li class="relative bg-gray-50 dark:bg-gray-600 py-2 px-6 hover:bg-gray-50 hover:dark:bg-gray-700 cursor-pointer">
        <p
          class="line-clamp-2 text-sm text-gray-600 dark:text-gray-300"
          @click="switchOrder">
          Date <span>({{ orderBy }})</span>
        </p>
      </li>
    </ul>
  </div>
</template>

<script>
  import { AdjustmentsIcon, ArrowDownIcon, ArrowUpIcon } from '@heroicons/vue/solid';

  export default {
    components: {
      AdjustmentsIcon,
      ArrowDownIcon,
      ArrowUpIcon,
    },
    props: {
      filters: {
        type: Array,
        required: true,
      },
      type: {
        type: Array,
        required: true,
      },
      orderBy: {
        type: String,
        required: true,
      },
      search: {
        type: String,
        required: true,
      },
      read: {
        type: Boolean,
        required: true,
      },
    },
    emits: [
      'update:search',
      'update:orderBy',
      'update:type',
      'update:read',
    ],
    data() {
      return {
        showFilters: false,
      };
    },
    methods: {
      switchOrder() {
        if (this.orderBy === 'desc') {
          this.$emit('update:orderBy', 'asc');
        } else {
          this.$emit('update:orderBy', 'desc');
        }
      },
      showUnread() {
        if (this.read === false) {
          this.$emit('update:read', true);
        } else {
          this.$emit('update:read', false);
        }
      },
      check(event) {
        const index = this.type.indexOf(event.target.name);
        const updatedTypesArray = [...this.type];

        if (event.target.value === 'true') {
          updatedTypesArray.push(event.target.name);
        } else {
          updatedTypesArray.splice(index, 1);
        }

        this.$emit('update:type', updatedTypesArray);
      },
    },
  };
</script>
