<template>
  <app-title text="Nav monthly" />
  <div v-if="data.labels">
    <dl class="mt-2 rounded-lg bg-white dark:bg-dark-theme-secondary overflow-hidden shadow divide-y divide-gray-200 p-5">
      <LineChart
        ref="lineChart"
        :key="currentTheme"
        :chart-data="data"
        :options="options" />
    </dl>
  </div>
</template>

<script>
  import { ref, onMounted } from 'vue';
  import { LineChart } from 'vue-chart-3';
  import { useStore } from 'vuex';
  import AppTitle from '../../../globalComponents/AppTitle.vue';
  import { plugins, themeDarkOptions, themeLightOptions } from '../../../helpers/chartThemeColorSet';

  export default {
    name: 'OverviewLineChart',
    components: {
      AppTitle,
      LineChart,
    },
    props: {
      fundsData: {
        type: Object,
        required: true,
      },
    },
    setup(props) {
      const store = useStore();

      const data = ref({
        labels: [],
        datasets: [
          {
            label: 'NAV',
            data: [],
            backgroundColor: '',
            fill: true,
            borderWidth: 2,
            borderColor: '#245091',
            pointBorderWidth: 2,
          },
        ],
      });

      let options = ref({});
      if (store.getters.theme === 'light' || !store.getters.theme) {
        options = { ...themeLightOptions, ...plugins };
      } else {
        options = { ...themeDarkOptions, ...plugins };
      }

      const lineChart = ref();

      const chartDataHandler = async () => {
        const labels = [];
        const nav = [];
        let lineColorDefault = '36, 80, 145';

        if (store.getters.theme === 'dark' && store.getters.theme) {
          lineColorDefault = '3, 52, 88';
        }

        const gradient = lineChart.value.chartInstance.ctx.createLinearGradient(30, 20, 10, 450);
        gradient.addColorStop(0, `rgba(${lineColorDefault}, 1)`);
        gradient.addColorStop(1, `rgba(${lineColorDefault}, 0.2)`);

        try {
          // TODO: cache testing
          // TODO: REFACTOR WITH OVERVIEW WEEKLY STATISTICS
          const monthlyData = [];

          Object.values(props.fundsData.per_year_per_month).forEach(monthly => {
            Object.values(monthly).forEach(item => {
              if (item.month !== undefined || item.year !== undefined) {
                monthlyData.push({
                  label: `${item.month}-${item.year}`,
                  nav: item.nav,
                  percentage: item.change_last_month,
                });
              }
            });
          });

          monthlyData.forEach(item => {
            labels.push(item.label);
            nav.push(item.nav);
          });

          data.value.labels = labels;
          data.value.datasets[0].data = nav;
          data.value.datasets[0].backgroundColor = gradient;
        } catch (error) {
          console.log(error);
        }
      };

      onMounted(() => {
        chartDataHandler();
      });

      return {
        lineChart,
        data,
        options,
        plugins,
        chartDataHandler,
      };
    },
    computed: {
      currentTheme() {
        return this.$store.getters.theme;
      },
    },
    watch: {
      currentTheme() {
        this.checkTheme();
        this.chartDataHandler();
      },
    },
    methods: {
      checkTheme() {
        if (this.$store.getters.theme === 'light' || !this.$store.getters.theme) {
          this.options = { ...themeLightOptions, ...plugins };
        } else {
          this.options = { ...themeDarkOptions, ...plugins };
        }
      },
    },
  };
</script>
