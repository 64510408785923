export default (router, store) => {
  router.beforeEach(async (to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
      if (store.getters.isLoggedIn) {
        await store.dispatch('sidebarOpen', false);
        next();
        return;
      }

      next('/login');
    } else {
      await store.dispatch('sidebarOpen', false);
      next();
    }
  });
};
