<template>
  <div class="p-3 lg:p-6">
    <dt class="text-base font-normal text-gray-900 dark:text-gray-300">
      {{ title }}
    </dt>
    <dd class="mt-1 flex justify-between items-baseline md:block lg:flex">
      <div
        class="flex items-baseline lg:text-2xl font-semibold text-theme-blue dark:text-gray-100">
        {{ data }}
      </div>

      <div
        v-if="label"
        :class="[label >= 0 ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800', 'inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0']">
        <ArrowSmUpIcon
          v-if="label >= 0"
          class="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500"
          aria-hidden="true" />
        <ArrowSmDownIcon
          v-else
          class="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500"
          aria-hidden="true" />
        <span class="sr-only"> {{ label >= 0 ? 'Increased' : 'Decreased' }} by </span>
        {{ label }}%
      </div>
    </dd>
  </div>
</template>

<script>
  import { ArrowSmDownIcon, ArrowSmUpIcon } from '@heroicons/vue/solid';

  export default {
    components: {
      ArrowSmUpIcon,
      ArrowSmDownIcon,
    },
    props: {
      title: {
        type: String,
        required: true,
      },
      data: {
        type: [String, Number],
        required: true,
      },
      label: {
        type: [String, Number],
        default: null,
      },
    },
  };
</script>
