<template>
  <TransitionRoot
    as="template"
    :show="$store.getters.sidebarOpen">
    <Dialog
      as="div"
      class="fixed inset-0 flex z-40 md:hidden"
      @close="$store.dispatch('sidebarOpen', false)">
      <TransitionChild
        as="template"
        enter="transition-opacity ease-linear duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="transition-opacity ease-linear duration-300"
        leave-from="opacity-100"
        leave-to="opacity-0">
        <DialogOverlay class="fixed inset-0 bg-gray-600 bg-opacity-75" />
      </TransitionChild>
      <TransitionChild
        as="template"
        enter="transition ease-in-out duration-300 transform"
        enter-from="-translate-x-full"
        enter-to="translate-x-0"
        leave="transition ease-in-out duration-300 transform"
        leave-from="translate-x-0"
        leave-to="-translate-x-full">
        <div class="relative flex-1 flex flex-col max-w-xs w-full bg-white dark:bg-dark-theme-secondary">
          <TransitionChild
            as="template"
            enter="ease-in-out duration-300"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="ease-in-out duration-300"
            leave-from="opacity-100"
            leave-to="opacity-0">
            <div class="absolute top-0 right-0 -mr-12 pt-2">
              <button
                type="button"
                class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                @click="$store.dispatch('sidebarOpen', false)">
                <span class="sr-only">Close sidebar</span>
                <XIcon
                  class="h-6 w-6 text-white"
                  aria-hidden="true" />
              </button>
            </div>
          </TransitionChild>
          <div class="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
            <div class="flex items-center flex-shrink-0 px-4">
              <img
                class="h-8 w-auto"
                :src="$store.getters.theme === 'dark' ? '/img/logo-white.png' : '/img/logo.png'"
                alt="Workflow">
              <h1 class="ml-3 font-medium text-gray-700 dark:text-gray-100 text-lg">
                Major Capital
              </h1>
            </div>
            <nav class="mt-5 flex-1 px-2 bg-white dark:bg-dark-theme-secondary space-y-1">
              <router-link
                v-for="item in navigation"
                :key="item.name"
                v-slot="{ isActive }"
                :to="{name: item.name}"
                active-class="bg-gray-200 text-gray-800 dark:bg-dark-theme-primary"
                :class="['text-gray-600 dark:text-gray-200 hover:bg-gray-50 dark:hover:bg-dark-theme-primary hover:text-gray-900', 'group flex items-center px-2 py-2 text-sm font-medium rounded-md']">
                <component
                  :is="item.icon"
                  :class="[isActive ? 'text-gray-800 dark:text-dark-theme-tertiary' : 'text-gray-400 group-hover:text-gray-300', 'mr-3 flex-shrink-0 h-6 w-6']"
                  aria-hidden="true" />
                <span class="flex-1">
                  {{ item.name }}
                </span>
                <span
                  v-if="$store.getters.newMessageNotifications && item.name === 'Message Center'"
                  :class="[item.current ? 'bg-white dark:bg-dark-theme-secondary' : 'bg-gray-100 dark:bg-dark-theme-tertiary group-hover:bg-gray-200 dark:group-hover:bg-dark-theme-tertiary', 'ml-3 inline-block py-0.5 px-3 text-xs font-medium rounded-full text-gray-500 dark:text-dark-theme-primary']">
                  {{ $store.getters.newMessageNotifications }}
                </span>
              </router-link>
            </nav>
          </div>
          <div class="flex-shrink-0 flex border-t border-gray-200 p-4 dark:border-gray-900">
            <p class="text-sm text-gray-700 dark:text-gray-300 flex-1">
              Dark Theme
            </p>
            <app-toggle />
          </div>
        </div>
      </TransitionChild>
      <div class="flex-shrink-0 w-14">
        <!-- Force sidebar to shrink to fit close icon -->
      </div>
    </Dialog>
  </TransitionRoot>

  <div class="hidden md:flex md:flex-shrink-0">
    <div class="flex flex-col w-64">
      <div class="flex-1 flex flex-col min-h-0 border-r border-gray-200 bg-white dark:bg-dark-theme-secondary dark:border-gray-900">
        <div class="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
          <div class="flex items-center flex-shrink-0 px-4">
            <img
              class="h-8 w-auto"
              :src="$store.getters.theme === 'dark' ? '/img/logo-white.png' : '/img/logo.png'"
              alt="Workflow">
            <h1 class="ml-3 font-medium text-gray-700 dark:text-gray-100 text-lg">
              Major Capital
            </h1>
          </div>
          <nav class="mt-5 flex-1 px-2 bg-white dark:bg-dark-theme-secondary space-y-1">
            <router-link
              v-for="item in navigation"
              :key="item.name"
              v-slot="{ isActive }"
              :to="{name: item.name}"
              active-class="bg-gray-200 text-gray-800 dark:bg-dark-theme-primary dark:text-dark-theme-tertiary"
              :class="['text-gray-600 dark:text-gray-200 hover:bg-gray-50 dark:hover:bg-dark-theme-primary hover:text-gray-900', 'group flex items-center px-2 py-2 text-sm font-medium rounded-md']">
              <component
                :is="item.icon"
                :class="[isActive ? 'text-gray-800 dark:text-dark-theme-tertiary' : 'text-gray-400 group-hover:text-gray-300', 'mr-3 flex-shrink-0 h-6 w-6']"
                aria-hidden="true" />
              <span class="flex-1">
                {{ item.name }}
              </span>
              <span
                v-if="$store.getters.newMessageNotifications && item.name === 'Message Center'"
                :class="[item.current ? 'bg-white dark:bg-dark-theme-secondary' : 'bg-gray-100 dark:bg-dark-theme-tertiary group-hover:bg-gray-200 dark:group-hover:bg-dark-theme-tertiary', 'ml-3 inline-block py-0.5 px-3 text-xs font-medium rounded-full text-gray-500 dark:text-dark-theme-primary']">
                {{ $store.getters.newMessageNotifications }}
              </span>
            </router-link>
          </nav>
        </div>
        <div class="flex-shrink-0 flex border-t border-gray-200 p-4 dark:border-gray-900">
          <p class="text-sm text-gray-700 dark:text-gray-300 flex-1">
            Dark Theme
          </p>
          <app-toggle />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    ChatAlt2Icon,
    HomeIcon,
    XIcon,
    ClipboardListIcon,
    ChatAltIcon,
  } from '@heroicons/vue/outline';

  import {
    Dialog, DialogOverlay, TransitionChild, TransitionRoot,
  } from '@headlessui/vue';
  import AppToggle from './AppToggle.vue';

  export default {
    components: {
      AppToggle,
      Dialog,
      DialogOverlay,
      TransitionChild,
      TransitionRoot,
      XIcon,
    },
    data() {
      return {
        navigation: [
          {
            name: 'Dashboard', icon: HomeIcon, current: true,
          },
          {
            name: 'Message Center', icon: ChatAlt2Icon, current: false,
          },
          {
            name: 'Onboarding Documents', icon: ClipboardListIcon, current: false,
          },
          {
            name: 'Contact', icon: ChatAltIcon, current: false,
          },
        ],
      };
    },
  };
</script>
