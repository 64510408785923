<template>
  <section
    :key="message.id"
    aria-labelledby="message-heading"
    class="min-w-0 transform flex-1 h-full flex flex-col xl:order-last"
    :class="{ 'left-96 absolute' : main }">
    <ul
      role="list"
      :class="{ 'py-4 space-y-2 sm:px-6 sm:space-y-4 lg:px-8': main }">
      <li
        class="bg-white dark:bg-dark-theme-secondary px-4 py-6 sm:px-6"
        :class="{ 'shadow sm:rounded-lg' : main}">
        <div class="sm:flex sm:justify-between sm:items-baseline">
          <h3 class="text-base font-medium">
            <span class="text-theme-blue dark:text-dark-theme-tertiary">{{ message.title }}</span>
          </h3>
          <p class="mt-1 text-sm text-gray-600 whitespace-nowrap sm:mt-0 sm:ml-3 dark:text-gray-300">
            <time datetime="2021-01-28T19:24">{{ message.publish_at }}</time>
          </p>
        </div>
        <div
          class="mt-4 space-y-6 text-sm text-gray-800 dark:text-gray-300"
          v-html="message.content">
        </div>
      </li>
    </ul>
  </section>
</template>

<script>
  export default {
    props: {
      message: {
        type: Object,
        required: true,
      },
      main: {
        type: Boolean,
        default: true,
      },
    },
  };
</script>
