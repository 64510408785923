<template>
  <auth-container title="Register">
    <div
      v-if="errors && errors.message"
      class="text-sm mt-1 text-center text-red-600">
      <p>{{ errors.message }}</p>
    </div>

    <form
      v-if="step === 1"
      class="space-y-6"
      @submit.prevent="create">
      <app-input
        ref="phoneCode"
        v-model:code="code"
        v-model:value="visualPhone"
        title="Phone number"
        name="phone"
        :phone="true"
        type="number"
        :errors="errors" />

      <app-button
        title="Create"
        :loading="nextLoading"
        :full-width="true" />
    </form>

    <form
      v-if="step === 2"
      ref="formStepSecond"
      class="space-y-6"
      @submit.prevent="SMSValidation">
      <div class="">
        <label
          for="numbers"
          class="block text-sm font-medium text-gray-700 dark:text-gray-300">
          SMS Code
        </label>
        <div class="mt-1 relative flex items-center">
          <template
            v-for="index in 7"
            :key="index">
            <div
              v-if="index === 4"
              class="flex-1 flex text-center justify-center dark:text-gray-50">
              -
            </div>
            <div
              v-else
              :class="[ index < 4 ? 'mr-2' : 'ml-2']">
              <input
                :id="index"
                v-model="numbers[index]"
                :name="index"
                type="text"
                maxLength="1"
                size="1"
                min="0"
                max="9"
                pattern="[0-9]{1}"
                class="appearance-none h-14 w-8 sm:h-20 sm:w-12 text-center dark:text-gray-200 dark:bg-dark-theme-secondary block px-1 sm:px-3 py-2 border border-gray-300 dark:divide-dark-theme-primary rounded-md focus:outline-none focus:ring-theme-blue focus:border-theme-blue dark:focus:ring-dark-theme-tertiary dark:focus:border-dark-theme-tertiary"
                @keyup.delete="focusPrevious($event.target)"
                @input="autoSwitchNext">
            </div>
          </template>
        </div>
        <p
          v-if="errors && errors[name]"
          id="password-error"
          class="text-sm text-red-600">
          {{ errors[name][0] }}
        </p>
      </div>

      <app-button
        title="Confirm"
        :loading="nextLoading"
        :full-width="true" />
    </form>

    <form
      v-if="step === 3"
      class="space-y-6"
      @submit.prevent="add">
      <app-input
        v-model:value="visualPhone"
        title="Phone number"
        :phone="true"
        name="phone"
        type="number"
        :errors="errors" />

      <app-input
        v-model:value="leadData.email"
        title="Email address"
        name="email"
        type="email"
        :errors="errors" />

      <app-input
        v-model:value="leadData.first_name"
        title="First name"
        name="first_name"
        :errors="errors" />

      <app-input
        v-model:value="leadData.last_name"
        title="Last name"
        name="last_name"
        :errors="errors" />

      <app-input
        v-model:value="leadData.password"
        name="password"
        type="password"
        :errors="errors" />

      <app-button
        title="Add"
        :loading="nextLoading"
        :full-width="true" />
    </form>
  </auth-container>
</template>

<script>
  import AppInput from '../../../globalComponents/AppInput.vue';
  import AppButton from '../../../globalComponents/AppButton.vue';
  import AuthContainer from '../components/AuthContainer.vue';
  import * as API from '../../../api/leads';

  function initialState() {
    return {
      step: 1,

      errors: null,

      visualPhone: '',

      code: 31,

      leadData: {
        phone: '',
        email: '',
        first_name: '',
        last_name: '',
        password: '',
      },

      nextLoading: false,

      numbers: {
        1: '',
        2: '',
        3: '',
        5: '',
        6: '',
        7: '',
      },

      validation_code: null,
    };
  }

  export default {
    name: 'Register',
    components: {
      AppInput,
      AppButton,
      AuthContainer,
    },
    data() {
      return initialState();
    },
    watch: {
      visualPhone(val) {
        this.setPhoneNumber(val);
      },
      code() {
        this.setPhoneNumber(this.visualPhone);
      },
    },
    methods: {
      setPhoneNumber(value) {
        if (!this.visualPhone) {
          this.leadData.phone = '';
        } else {
          this.leadData.phone = `${this.code}${parseInt(value, 10)}`;
        }
      },
      async create() {
        try {
          this.nextLoading = true;
          await API.setLead({ phone: this.leadData.phone });

          this.nextLoading = false;
          this.step = 2;
          setTimeout(() => {
            this.$refs.formStepSecond[0].focus();
          }, 300);
          this.errors = null;
        } catch (error) {
          this.nextLoading = false;
          if (error.response && error.response.data) {
            this.errors = error.response.data;
          } else {
            console.log(error.phone);
            this.errors = error;
          }
        }
      },
      async SMSValidation() {
        try {
          this.nextLoading = true;
          let combinedNumbers = '';
          Object.values(this.numbers).forEach(item => { combinedNumbers += item; });

          await API.setValidatePhone({
            phone: this.leadData.phone,
            validation_code: combinedNumbers,
            validation_type: 'register_lead',
          });

          this.nextLoading = false;
          this.step = 3;
          this.errors = null;
        } catch (error) {
          this.nextLoading = false;
          this.errors = error.response.data;
        }
      },
      async add() {
        try {
          this.nextLoading = true;
          await API.setAddLead(this.leadData);

          await this.$store.dispatch('setNotification', {
            title: 'Account created!',
            message: 'The demo account has been created',
          });

          const email = this.leadData.email;
          const password = this.leadData.password;
          await this.$store.dispatch('login', { email, password });

          Object.assign(this.$data, initialState());
          this.nextLoading = false;

          await this.$router.push({ path: '/' });
        } catch (error) {
          this.nextLoading = false;
          this.errors = error.response.data;
        }
      },
      focusNext(elem) {
        const currentIndex = Array.from(elem.form.elements).indexOf(elem);
        elem.form.elements.item(currentIndex < elem.form.elements.length - 1 ? currentIndex + 1 : 0).focus();

        if (currentIndex === 5) this.SMSValidation();
      },
      focusPrevious(elem) {
        const currentIndex = Array.from(elem.form.elements).indexOf(elem);
        elem.form.elements.item(currentIndex > 1 ? currentIndex - 1 : 0).focus();
      },
      autoSwitchNext(event) {
        if (event.data) {
          this.focusNext(event.target);
        }
      },
    },
  };
</script>
