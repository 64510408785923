<template>
  <div class="min-h-full h-full pt-16 pb-12 flex flex-col bg-gray-100 dark:bg-dark-theme-primary">
    <main class="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
      <div class="flex-shrink-0 flex justify-center">
        <a
          href="/"
          class="inline-flex">
          <span class="sr-only">Major Capital</span>
          <img
            class="h-24 w-auto"
            src="/img/logo.png"
            alt="Logo">
        </a>
      </div>
      <div class="py-4">
        <div class="text-center">
          <p class="text-sm font-semibold text-theme-blue dark:text-dark-theme-tertiary uppercase tracking-wide">
            404 error
          </p>
          <h1 class="mt-4 mb-4 text-4xl font-extrabold text-gray-900 dark:text-dark-theme-tertiary tracking-tight sm:text-5xl">
            Page not found.
          </h1>
          <p class="mt-2 text-base text-gray-500">
            Sorry, we couldn’t find the page you’re looking for.
          </p>
          <div class="mt-6">
            <router-link
              :to="{ name: 'Dashboard' }"
              class="text-base font-medium text-theme-blue dark:text-dark-theme-tertiary hover:text-indigo-500">
              Go back home
              <span aria-hidden="true"> &rarr;</span>
            </router-link>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
