<template>
  <div
    class="overflow-x-auto sm:-mx-6 lg:-mx-8 relative"
    :style="[!stylingOff ? styling : '']">
    <div
      ref="checkTableHeight"
      class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8 absolute lg:relative w-full lg:w-auto overflow-x-auto"
      :class="additionalClass">
      <div class="shadow overflow-x-auto border-b border-gray-200 dark:border-dark-theme-primary sm:rounded-lg">
        <table class="min-w-full table-auto divide-y divide-gray-200 dark:divide-dark-theme-primary">
          <thead class="bg-gray-50 dark:bg-dark-theme-primary">
            <tr>
              <th
                v-for="(header, index) in headerData"
                :key="index"
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                {{ header }}
              </th>
            </tr>
          </thead>

          <slot>
            <tbody>
              <tr>
                <td class="p-3 text-gray-800 dark:text-gray-200">
                  No results...
                </td>
              </tr>
            </tbody>
          </slot>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      headerData: {
        type: Array,
        required: true,
      },
      additionalClass: {
        type: [String, Array],
        default: null,
      },
      stylingOff: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        styling: { height: '356px' },
      };
    },
    mounted() {
      this.styling.height = `${this.$refs.checkTableHeight.offsetHeight}px`;
    },
  };
</script>
