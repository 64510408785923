<template>
  <div class="min-h-screen bg-gray-50 dark:bg-dark-theme-primary flex flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <img
        class="mx-auto h-20 w-auto"
        :src="$store.getters.theme === 'dark' ? '/img/logo-white.png' : '/img/logo.png'"
        alt="Logo">
      <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900 dark:text-dark-theme-tertiary">
        {{ title }}
      </h2>
    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md mx-5">
      <div class="bg-white dark:bg-dark-theme-secondary py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <slot></slot>
      </div>
      <slot name="under-box"></slot>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        required: true,
      },
    },
  };
</script>
