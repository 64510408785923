<template>
  <div class="py-6">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
      <h1 class="text-2xl font-semibold text-gray-900 dark:text-gray-200">
        Contact
      </h1>
    </div>
    <div class="mt-5 max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
      <form @submit.prevent="contactSharpSpringForm">
        <div class="shadow sm:rounded-md sm:overflow-hidden">
          <div class="bg-white dark:bg-dark-theme-secondary py-6 px-4 space-y-6 sm:p-6">
            <div>
              <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300">
                Contact for onboarding
              </h3>
              <p class="mt-1 text-sm text-gray-500 dark:text-gray-300">
                Fill out the form to get in contact
              </p>
            </div>

            <div class="grid grid-cols-6 gap-6">
              <app-input
                v-model:value="first_name"
                name="first_name"
                title="First name"
                custom-class="col-span-6 sm:col-span-3"
                :errors="errors" />

              <app-input
                v-model:value="last_name"
                name="last_name"
                title="Last name"
                custom-class="col-span-6 sm:col-span-3"
                :errors="errors" />

              <app-input
                ref="phoneCode"
                v-model:code="code"
                v-model:value="visualPhone"
                title="Phone number"
                name="phone"
                :phone="true"
                type="number"
                custom-class="col-span-6 sm:col-span-3"
                :errors="errors" />

              <app-input
                v-model:value="email"
                name="email"
                type="email"
                title="Email address"
                custom-class="col-span-6 sm:col-span-3"
                :errors="errors" />

              <div class="col-span-6">
                <label
                  for="comment"
                  class="block text-sm font-medium text-gray-700 dark:text-gray-300">Description</label>
                <div class="mt-1">
                  <textarea
                    id="comment"
                    v-model="description"
                    rows="4"
                    name="description"
                    class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md dark:text-gray-200 dark:bg-dark-theme-secondary dark:divide-dark-theme-primary dark:focus:ring-dark-theme-tertiary dark:focus:border-dark-theme-tertiary"></textarea>
                </div>
              </div>
            </div>
          </div>
          <div class="px-4 pb-6 bg-gray-50 dark:bg-dark-theme-secondary text-right sm:px-6">
            <app-button title="Send" />
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
  import axios from 'axios';
  import AppInput from '../../../globalComponents/AppInput.vue';
  import AppButton from '../../../globalComponents/AppButton.vue';

  export default {
    components: {
      AppInput,
      AppButton,
    },
    data() {
      return {
        code: 31,
        visualPhone: '',

        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        description: '',
        errors: null,
      };
    },
    watch: {
      visualPhone(val) {
        this.setPhoneNumber(val);
      },
      code() {
        this.setPhoneNumber(this.visualPhone);
      },
    },
    methods: {
      validator(keyName, name, value) {
        if (keyName === '' || keyName === null || keyName.value === 0) {
          this.errors = { ...this.errors, ...{ [name]: [`${value} field is required`] } };
        }
      },
      setPhoneNumber(value) {
        if (!this.visualPhone) {
          this.phone = '';
        } else {
          this.phone = `${this.code}${parseInt(value, 10)}`;
        }
      },
      async contactSharpSpringForm() {
        this.errors = {};
        this.validator(this.first_name, 'first_name', 'First Name');
        this.validator(this.last_name, 'last_name', 'Last Name');
        this.validator(this.phone, 'phone', 'Phone');
        this.validator(this.email, 'email', 'Email');
        this.validator(this.description, 'description', 'Description');

        if (Object.keys(this.errors).length === 0) {
          const baseURL = 'https://app-3QNTC24TRG.marketingautomation.services/webforms/receivePostback/MzawMLE0MTA0AgA/';
          const endPoint = '72a67119-7836-4365-96df-b4856127dd67';

          axios({
            method: 'POST',
            url: `${endPoint}/jsonp/?`,
            baseURL,
            params: {
              FirstName: this.first_name,
              LastName: this.last_name,
              Mobile: this.phone,
              Email: this.email,
              Description: this.description,
            },
          });

          this.visualPhone = '';
          this.first_name = '';
          this.last_name = '';
          this.phone = '';
          this.email = '';
          this.description = '';

          await this.$store.dispatch('setNotification', {
            title: 'Contact form send!',
            message: 'We will reach out as soon as possible',
          });
        }
      },
    },
  };
</script>
