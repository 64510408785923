import axios from 'axios';

export const getAllMessages = fundId => {
  return axios.get(`/funds/${fundId}/messages/`);
};

export const getMessageById = (fund, id) => {
  return axios.get(`/funds/${fund}/messages/${id}`);
};

export const setMessageReadById = id => {
  return axios.post(`/messages/${id}/read/`);
};
