export default {
  auth_request(state) {
    state.status = 'loading';
  },
  auth_success(state, { user, token }) {
    state.status = 'success';
    state.user = user;
    state.token = token;
  },
  logout(state) {
    state.status = '';
    state.token = '';
    state.coinsToken = '';
    state.user = null;
  },
  set_fund(state, fund) {
    state.fund = fund;
  },
  set_fund_nav(state, fundNav) {
    state.fundNav = fundNav;
  },
  set_selected_fund(state, selectedFund) {
    state.selectedFund = selectedFund;
  },
  set_messages(state, messages) {
    state.messages = messages;
  },
  number_new_message(state, number) {
    state.newMessageNotifications = number;
  },
  show_messages_sidebar(state, bool) {
    state.showMessagesSidebar = bool;
  },
  set_transactions(state, transactions) {
    state.transactions = transactions;
  },
  set_notification(state, notification) {
    state.notifications.push(notification);
  },
  remove_notification(state, payload) {
    state.notifications.splice(payload.id, 1);
  },
  switch_theme(state, theme) {
    state.theme = theme;
  },
  set_message_to_read_by_id(state, id) {
    if (state.newMessageNotifications > 0 && state.messages.find(message => message.id === id).read.read !== 1) {
      state.newMessageNotifications -= 1;
    }

    state.messages.find(message => message.id === id).read.read = 1;
  },
  set_sidebar_value(state, bool) {
    state.sidebarOpen = bool;
  },
};
