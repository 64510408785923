import axios from 'axios';

export const setLead = data => {
  return axios.post('leads/create', data);
};

export const setValidatePhone = data => {
  return axios.post('validate/phone-number', data);
};

export const setAddLead = data => {
  return axios.put('leads/add', data);
};
