import Page from '../../GeneralViews/Page.vue';
import Contact from '../pages/Index.vue';
import OnboardingDocuments from '../pages/OnboardingDocuments.vue';

export default [
  {
    path: '/contact',
    component: Page,
    children: [
      {
        path: '',
        name: 'Contact',
        component: Contact,
      },
    ],
    meta: { requiresAuth: true },
  },
  {
    path: '/onboarding-documents',
    component: Page,
    children: [
      {
        path: '',
        name: 'Onboarding Documents',
        component: OnboardingDocuments,
      },
    ],
    meta: { requiresAuth: true },
  },
];
