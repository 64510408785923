export default {
  methods: {
    number(value, decimals, options) {
      if (typeof value === 'undefined') return '';

      if (decimals !== undefined) {
        options.minimumFractionDigits = decimals;
        options.maximumFractionDigits = decimals;
      }

      return value.toLocaleString('nl-NL', options);
    },
    money(value) {
      const float = parseFloat(value);
      const options = {
        style: 'currency',
        currency: 'EUR',
      };
      return this.number(float, 2, options);
    },
    hex2rgba(hex, alpha = 1) {
      const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
      return `rgba(${r},${g},${b},${alpha})`;
    },
  },
};
