<template>
  <auth-container title="Sign in to your account">
    <form
      class="space-y-4"
      @submit.prevent="login">
      <div
        v-if="errors && errors.message"
        class="text-sm mt-1 text-center text-red-600">
        <p>{{ errors.message }}</p>
      </div>

      <app-input
        v-model:value="email"
        title="Email address"
        name="email"
        type="email"
        :errors="errors" />

      <app-input
        v-model:value="password"
        name="password"
        type="password"
        :errors="errors" />

      <app-button
        title="Sign in"
        :loading="loginLoading"
        :full-width="true" />

      <!--          <div class="flex items-center justify-between">-->
      <!--            <div class="flex items-center">-->
      <!--              <input-->
      <!--                id="remember-me"-->
      <!--                name="remember-me"-->
      <!--                type="checkbox"-->
      <!--                class="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded">-->
      <!--              <label-->
      <!--                for="remember-me"-->
      <!--                class="ml-2 block text-sm text-gray-900">-->
      <!--                Remember me-->
      <!--              </label>-->
      <!--            </div>-->

      <!--            <div class="text-sm">-->
      <!--              <a-->
      <!--                href="#"-->
      <!--                class="font-medium text-indigo-600 hover:text-indigo-500">-->
      <!--                Forgot your password?-->
      <!--              </a>-->
      <!--            </div>-->
      <!--          </div>-->
    </form>
    <template #under-box>
      <div class="text-sm text-gray-500 dark:text-gray-400 dark:hover:text-dark-theme-tertiary hover:text-theme-blue mt-3 text-center">
        <router-link :to="{ name: 'Register' }">
          Create demo account
        </router-link>
      </div>
    </template>
  </auth-container>
</template>

<script>
  import AppInput from '../../../globalComponents/AppInput.vue';
  import AppButton from '../../../globalComponents/AppButton.vue';
  import AuthContainer from '../components/AuthContainer.vue';

  export default {
    name: 'Login',
    components: {
      AuthContainer,
      AppButton,
      AppInput,
    },
    data() {
      return {
        email: '',
        password: '',
        errors: null,
        loginLoading: false,
      };
    },
    methods: {
      async login() {
        const email = this.email;
        const password = this.password;
        this.loginLoading = true;

        try {
          await this.$store.dispatch('login', { email, password });
          await this.$router.push({ path: '/' });
        } catch (error) {
          this.loginLoading = false;
          this.errors = error.response.data;
        }
      },
    },
  };
</script>
