<template>
  <div>
    <aside class="block flex-shrink-0 order-first">
      <div
        class="fixed flex flex-col w-full sm:w-96 border-r border-gray-200 dark:border-gray-900 bg-gray-100 dark:bg-dark-theme-secondary"
        style="height: calc(100% - 64px)">
        <div class="flex-shrink-0">
          <div class="h-16 bg-white dark:bg-dark-theme-secondary px-6 flex flex-col justify-center">
            <div class="flex items-baseline space-x-3">
              <h2 class="text-lg font-medium text-gray-900 dark:text-gray-100">
                Messages
              </h2>
              <p
                v-if="messages && newItems"
                class="text-sm font-medium text-gray-500 dark:text-gray-300">
                {{ newItems }} new
              </p>
            </div>
          </div>
        </div>
        <messages-filters
          v-model:type="selectedFilters.type"
          v-model:search="selectedFilters.search"
          v-model:order-by="selectedFilters.orderBy"
          v-model:read="selectedFilters.showUnread"
          :filters="filters" />
        <messages-overview
          v-if="messages"
          :sorted-items="sortedItems"
          @selectMessage="selectMessage" />
        <app-spinner v-if="!messages" />
      </div>
    </aside>
    <transition
      enter-active-class="transform ease-out duration-300 transition"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transform transition ease-in duration-300"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0">
      <div
        v-if="message"
        class="hidden sm:flex">
        <messages-container :message="message" />
      </div>
    </transition>
  </div>

  <TransitionRoot
    as="template"
    :show="messageOverlay">
    <Dialog
      as="div"
      class="fixed inset-0 flex z-40 md:hidden">
      <TransitionChild
        as="template"
        enter="transition-opacity ease-linear duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="transition-opacity ease-linear duration-300"
        leave-from="opacity-100"
        leave-to="opacity-0">
        <DialogOverlay class="fixed inset-0 bg-gray-600 bg-opacity-75" />
      </TransitionChild>
      <TransitionChild
        as="template"
        enter="transition ease-in-out duration-300 transform"
        enter-from="translate-x-full"
        enter-to="translate-x-0"
        leave="transition ease-in-out duration-300 transform"
        leave-from="translate-x-0"
        leave-to="translate-x-full">
        <div
          class="relative flex-1 flex flex-col w-full bg-white dark:bg-dark-theme-secondary"
          style="left: 60px; max-width: calc(100% - 60px)">
          <TransitionChild
            as="template"
            enter="ease-in-out duration-300"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="ease-in-out duration-300"
            leave-from="opacity-100"
            leave-to="opacity-0">
            <div
              class="absolute top-0 h-full"
              style="left: -60px">
              <button
                type="button"
                class="h-full flex justify-center rounded-full focus:outline-none pt-3"
                style="width: 60px"
                @click="messageOverlay = false">
                <span class="sr-only">Close sidebar</span>
                <XIcon
                  class="h-6 w-6 text-white"
                  aria-hidden="true" />
              </button>
            </div>
          </TransitionChild>
          <template v-if="message">
            <messages-container
              :message="message"
              :main="false" />
          </template>
        </div>
      </TransitionChild>
    </dialog>
  </transitionroot>
</template>

<script>
  import { XIcon } from '@heroicons/vue/outline';
  import {
    Dialog, DialogOverlay, TransitionChild, TransitionRoot,
  } from '@headlessui/vue';
  import MessagesFilters from '../components/MessagesFilters.vue';
  import MessagesContainer from '../components/MessagesContainer.vue';
  import MessagesOverview from '../components/MessagesOverview.vue';
  import AppSpinner from '../../../globalComponents/AppSpinner.vue';
  import * as API from '../../../api/messages';

  export default {
    name: 'MessageCenter',
    components: {
      AppSpinner,
      MessagesFilters,
      MessagesContainer,
      MessagesOverview,
      Dialog,
      DialogOverlay,
      XIcon,
      TransitionChild,
      TransitionRoot,
    },
    data() {
      return {
        messageOverlay: false,
        message: null,
        filters: [
          { name: 'message', label: 'Notification', selected: false },
          { name: 'release_note', label: 'Release Notes', selected: false },
        ],
        selectedFilters: {
          type: [],
          orderBy: 'desc',
          search: '',
          showUnread: false,
        },
      };
    },
    computed: {
      messages() {
        return this.$store.getters.messages;
      },
      currentFund() {
        return this.$store.getters.selectedFund;
      },
      newItems() {
        return this.messages.filter(item => item.read.read !== 1).length;
      },
      sortedItems() {
        let messages = this.$store.getters.messages;

        if (this.selectedFilters.search) {
          messages = messages.filter(item => {
            return item.title.toLowerCase().includes(this.selectedFilters.search.toLowerCase());
          });
        }

        if (this.selectedFilters.type.length) {
          messages = messages.filter(item => {
            return this.selectedFilters.type.includes(item.type.code);
          });
        }

        if (this.selectedFilters.showUnread === true) {
          messages = messages.filter(item => item.read.read !== 1);
        }

        if (this.selectedFilters.orderBy === 'desc') {
          messages = messages.sort((a, b) => new Date(b.publish_at) - new Date(a.publish_at));
        } else {
          messages = messages.sort((a, b) => new Date(a.publish_at) - new Date(b.publish_at));
        }

        return messages;
      },
    },
    watch: {
      async $route(to) {
        if (to.name !== 'Message Center' || Object.keys(this.$route.params).length === 0) return;

        await this.selectMessage(this.$route.params.id, true);

        try {
          await API.setMessageReadById(this.$route.params.id);
        } catch (error) {
          console.log(error);
        }
      },
      currentFund() {
        if (this.$route.params.id) {
          this.selectMessage(this.$route.params.id);
        }
      },
    },
    async mounted() {
      if (this.$route.params.id && this.$store.getters.selectedFund) {
        await this.selectMessage(this.$route.params.id, true);

        try {
          await API.setMessageReadById(this.$route.params.id);
        } catch (error) {
          console.log(error);
        }
      }
    },
    methods: {
      async selectMessage(id) {
        try {
          const response = await API.getMessageById(this.$store.getters.selectedFund, id);
          this.message = response.data;

          this.messageOverlay = false;

          await this.$router.push({ name: 'Message Center', params: { id } });
        } catch (error) {
          console.log(error);
        }
      },
    },
  };
</script>
