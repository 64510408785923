<template>
  <div class="py-6">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
      <h1 class="text-2xl font-semibold text-gray-900 dark:text-gray-200">
        Settings
      </h1>
    </div>
    <div class="mt-5 max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
      <div class="lg:grid lg:grid-cols-12 lg:gap-x-5">
        <aside class="py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-3">
          <nav class="space-y-1">
            <a
              v-for="(item, index) in navigation"
              :key="item.name"
              :class="[item.current ? 'bg-gray-50 dark:bg-dark-theme-secondary text-theme-blue dark:text-dark-theme-tertiary hover:text-indigo-700 hover:bg-white' : 'text-gray-900 dark:text-gray-300 hover:text-gray-900 dark:hover:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-800', 'group rounded-md px-3 py-2 flex items-center text-sm font-medium']"
              :aria-current="item.current ? 'page' : undefined"
              @click.prevent="switchSelected(index)">
              <component
                :is="item.icon"
                :class="[item.current ? 'text-theme-blue dark:text-dark-theme-tertiary' : 'text-gray-400 group-hover:text-gray-500', 'flex-shrink-0 -ml-1 mr-3 h-6 w-6']"
                aria-hidden="true" />
              <span class="truncate">
                {{ item.name }}
              </span>
            </a>
          </nav>
        </aside>

        <div
          v-if="$store.getters.user"
          class="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
          <personal-info v-if="navigation[0].current" />
          <password-change v-if="navigation[1].current" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { KeyIcon, UserCircleIcon } from '@heroicons/vue/outline';
  import PersonalInfo from '../components/PersonalInfo.vue';
  import PasswordChange from '../components/PasswordChange.vue';

  export default {
    components: {
      PersonalInfo,
      PasswordChange,
    },
    data() {
      return {
        navigation: [
          {
            name: 'Account', href: '#', icon: UserCircleIcon, current: true,
          },
          {
            name: 'Password', href: '#', icon: KeyIcon, current: false,
          },
        ],
      };
    },
    methods: {
      switchSelected(value) {
        for (let i = 0; i < this.navigation.length; i += 1) {
          this.navigation[i].current = false;
        }

        this.navigation[value].current = true;
      },
    },
  };
</script>
