<template>
  <app-title text="Participations per fund" />
  <div v-if="isComponentLoading">
    <div class="mt-2 relative flex items-center rounded-lg bg-white dark:bg-dark-theme-secondary overflow-hidden shadow p-5">
      <app-spinner />
    </div>
  </div>
  <div
    v-if="doughnutChartData.labels && !isComponentLoading"
    class="mt-2 flex flex-col lg:flex-row rounded-lg bg-white dark:bg-dark-theme-secondary overflow-hidden shadow p-5">
    <div class="flex flex-col items-center min-w-350">
      <div class="w-48">
        <DoughnutChart
          ref="doughnutChart"
          :chart-data="doughnutChartData"
          :options="doughnutChartOptions" />
      </div>
    </div>
    <div
      v-if="tableInformationData"
      class="w-full relative">
      <app-table
        :header-data="tableHeaderData"
        :styling-off="true">
        <tbody>
          <tr
            v-for="info in tableInformationData"
            :key="info.id"
            class="bg-white dark:bg-dark-theme-secondary">
            <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-gray-300">
              {{ info.name }}
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-gray-300">
              {{ parseFloat(info.percentage_invested).toFixed(2) }}%
            </td>
          </tr>
        </tbody>
      </app-table>
    </div>
  </div>
</template>

<script>
  import { DoughnutChart } from 'vue-chart-3';
  import * as FundsApi from '../../../api/funds';
  import AppTitle from '../../../globalComponents/AppTitle.vue';
  import AppSpinner from '../../../globalComponents/AppSpinner.vue';
  import AppTable from '../../../globalComponents/AppTable.vue';

  export default {
    components: {
      AppTitle,
      AppSpinner,
      AppTable,
      DoughnutChart,
    },
    data() {
      return {
        tableHeaderData: ['Name', 'Percentage Invested'],
        isComponentLoading: true,
        tableInformationData: {},
        doughnutChartData: {
          labels: [],
          datasets: [
            {
              data: [],
              backgroundColor: ['#B0B381', '#6BA0C0', '#7F1590', '#CB3FEF'],
            },
          ],
        },
        doughnutChartOptions: {
          elements: {
            arc: {
              borderWidth: 1,
            },
          },
          plugins: {
            legend: {
              display: false,
            },
          },
        },
      };
    },
    created() {
      this.isComponentLoading = false;
      this.getParticipationsPerFundsData();
    },
    methods: {
      async getParticipationsPerFundsData() {
        const labelsList = [];
        const dataList = [];
        const response = await FundsApi.getFundDemoInformation(13);

        this.tableInformationData = response.data;

        response.data.forEach(item => {
          labelsList.push(item.name);
          dataList.push(item.percentage_invested);
        });

        this.doughnutChartData.labels = labelsList;
        this.doughnutChartData.datasets[0].data = dataList;
      },
    },
  };
</script>
