<template>
  <app-title
    text="Essential Information"
    subtitle="What to expect conform the Essential Information Document - Major Capital Hybrid Fund - AFM ID: 50027443" />
  <app-table :header-data="tableHeaderData">
    <tbody>
      <tr
        v-for="(item, index) in tbody"
        :key="index"
        :class="index % 2 === 0 ? 'bg-white dark:bg-dark-theme-secondary' : 'bg-gray-50 dark:bg-dark-theme-primary'">
        <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 flex">
          <div style="min-width: 200px">
            <span class="dark:text-gray-300">
              {{ item.title }}
            </span>
          </div>
          <div class="flex flex-col">
            <span class="dark:text-gray-300">
              {{ item.info_head }}
            </span>
            <span class="text-gray-500 dark:text-gray-300">
              {{ item.info_body }}
            </span>
          </div>
        </td>
        <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
          <div class="flex flex-col">
            <span class="dark:text-gray-300">
              {{ money(item.one_year.value) }}
            </span>
            <span class="text-gray-500 dark:text-gray-300">
              {{ Number(item.one_year.percentage).toFixed(1) }}%
            </span>
          </div>
        </td>
        <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
          <div class="flex flex-col">
            <span class="dark:text-gray-300">
              {{ money(item.three_year.value) }}
            </span>
            <span class="text-gray-500 dark:text-gray-300">
              {{ Number(item.three_year.percentage).toFixed(1) }}%
            </span>
          </div>
        </td>
        <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
          <div class="flex flex-col">
            <span class="dark:text-gray-300">
              {{ money(item.five_year.value) }}
            </span>
            <span class="text-gray-500 dark:text-gray-300">
              {{ Number(item.five_year.percentage).toFixed(1) }}%
            </span>
          </div>
        </td>
      </tr>
    </tbody>
  </app-table>
</template>

<script>
  import AppTitle from '../../../globalComponents/AppTitle.vue';
  import AppTable from '../../../globalComponents/AppTable.vue';

  export default {
    components: {
      AppTitle,
      AppTable,
    },
    data() {
      return {
        tableHeaderData: [
          'Belegging EUR 10.000',
          '1 jaar',
          '3 jaar',
          '5 jaar',
        ],
        tbody: [
          {
            title: 'Stress scenario',
            info_head: 'Wat u terug zou krijgen na aftrek van kosten',
            info_body: 'Gemiddeld jaarlijks rendement',
            one_year: {
              value: 8620,
              percentage: -13.8,
            },
            three_year: {
              value: 9220,
              percentage: -7.8,
            },
            five_year: {
              value: 9570,
              percentage: -4.3,
            },
          },
          {
            title: 'Ongunstig scenario',
            info_head: 'Wat u terug zou krijgen na aftrek van kosten',
            info_body: 'Gemiddeld jaarlijks rendement',
            one_year: {
              value: 10272,
              percentage: 2.7,
            },
            three_year: {
              value: 11022,
              percentage: 10.2,
            },
            five_year: {
              value: 22749,
              percentage: 227.5,
            },
          },
          {
            title: 'Gematigd scenario',
            info_head: 'Wat u terug zou krijgen na aftrek van kosten',
            info_body: 'Gemiddeld jaarlijks rendement',
            one_year: {
              value: 17000,
              percentage: 70,
            },
            three_year: {
              value: 36252,
              percentage: 262.5,
            },
            five_year: {
              value: 74824,
              percentage: 748.2,
            },
          },
          {
            title: 'Gunstig scenario',
            info_head: 'Wat u terug zou krijgen na aftrek van kosten',
            info_body: 'Gemiddeld jaarlijks rendement',
            one_year: {
              value: 27500,
              percentage: 175,
            },
            three_year: {
              value: 75629,
              percentage: 656.3,
            },
            five_year: {
              value: 156099,
              percentage: 1561,
            },
          },
        ],
      };
    },
  };
</script>
