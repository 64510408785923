<template>
  <div class="py-6">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
      <h1 class="text-2xl font-semibold text-gray-900 dark:text-gray-200">
        Documents
      </h1>
    </div>
    <div class="mt-5 max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
      <form @submit.prevent="contactSharpSpringForm">
        <div class="shadow sm:rounded-md sm:overflow-hidden">
          <div class="bg-white dark:bg-dark-theme-secondary py-6 px-4 space-y-6 sm:p-6">
            <div>
              <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300">
                Download Onboarding Documents
              </h3>
              <p class="mt-1 text-sm text-gray-500 dark:text-gray-300">
                Fill out the form and download the onboarding documents
              </p>
            </div>

            <div class="grid grid-cols-6 gap-6">
              <app-input
                v-model:value="capital"
                name="capital"
                title="Capital"
                type="number"
                custom-class="col-span-6"
                :errors="errors" />

              <app-input
                v-model:value="first_name"
                name="first_name"
                title="First name"
                custom-class="col-span-6 sm:col-span-3"
                :errors="errors" />

              <app-input
                v-model:value="last_name"
                name="last_name"
                title="Last name"
                custom-class="col-span-6 sm:col-span-3"
                :errors="errors" />

              <app-input
                v-model:value="email"
                name="email"
                type="email"
                title="Email address"
                custom-class="col-span-6 sm:col-span-3"
                :errors="errors" />

              <app-input
                ref="phoneCode"
                v-model:code="code"
                v-model:value="visualPhone"
                title="Phone number"
                name="phone"
                :phone="true"
                type="number"
                custom-class="col-span-6 sm:col-span-3"
                :errors="errors" />
            </div>
          </div>
          <div class="px-4 pb-6 bg-gray-50 dark:bg-dark-theme-secondary text-right sm:px-6">
            <app-button title="Download" />
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
  import axios from 'axios';
  import AppInput from '../../../globalComponents/AppInput.vue';
  import AppButton from '../../../globalComponents/AppButton.vue';

  export default {
    components: {
      AppInput,
      AppButton,
    },
    data() {
      return {
        code: 31,
        visualPhone: '',

        capital: '',
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        errors: {},
      };
    },
    watch: {
      visualPhone(val) {
        this.setPhoneNumber(val);
      },
      code() {
        this.setPhoneNumber(this.visualPhone);
      },
    },
    methods: {
      validator(keyName, name, value) {
        if (keyName === '' || keyName === null || keyName.value === 0) {
          this.errors = { ...this.errors, ...{ [name]: [`${value} field is required`] } };
        }
      },
      setPhoneNumber(value) {
        if (!this.visualPhone) {
          this.phone = '';
        } else {
          this.phone = `${this.code}${parseInt(value, 10)}`;
        }
      },
      async contactSharpSpringForm() {
        this.errors = {};
        this.validator(this.capital, 'capital', 'Capital');
        this.validator(this.first_name, 'first_name', 'First Name');
        this.validator(this.last_name, 'last_name', 'Last Name');
        this.validator(this.phone, 'phone', 'Phone');
        this.validator(this.email, 'email', 'Email');

        if (Object.keys(this.errors).length === 0) {
          const baseURL = 'https://app-3QNTC24TRG.marketingautomation.services/webforms/receivePostback/MzawMLE0MTA0AgA/';
          const endPoint = '3b77272a-09b2-431a-85b9-f175b2662979';

          axios({
            method: 'POST',
            url: `${endPoint}/jsonp/?`,
            baseURL,
            params: {
              AvailableCapital: this.capital,
              FirstName: this.first_name,
              LastName: this.last_name,
              Mobile: this.phone,
              Email: this.email,
            },
          });

          this.visualPhone = '';
          this.capital = '';
          this.first_name = '';
          this.last_name = '';
          this.phone = '';
          this.email = '';

          await this.$store.dispatch('setNotification', {
            title: 'Onboarding form send!',
            message: 'We will reach out as soon as possible',
          });

          window.open('https://koi-3qntc24trg.marketingautomation.services/net/m?md=9TsS6Ly9MiAo5o3YkF9x2cyCnW591dcP', '_blank');
        }
      },
    },
  };
</script>
