import MessageCenter from '../pages/Index.vue';
import Page from '../../GeneralViews/Page.vue';

export default [
  {
    path: '/message-center',
    component: Page,
    children: [
      {
        path: ':id?',
        name: 'Message Center',
        component: MessageCenter,
      },
    ],
    meta: { requiresAuth: true },
  },
];
