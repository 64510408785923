<template>
  <form @submit.prevent="updatePassword">
    <div class="shadow sm:rounded-md sm:overflow-hidden">
      <div class="bg-white dark:bg-dark-theme-secondary py-6 px-4 space-y-6 sm:p-6">
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300">
            Password change
          </h3>
          <p class="mt-1 text-sm text-gray-500 dark:text-gray-300">
            Change your current password
          </p>
        </div>

        <div class="grid grid-cols-6 gap-6">
          <app-input
            v-model:value="old_password"
            custom-class="col-span-6"
            name="old_password"
            type="password"
            title="Current password"
            :errors="errors" />

          <app-input
            v-model:value="new_password"
            custom-class="col-span-6"
            name="new_password"
            type="password"
            title="New password"
            :errors="errors" />

          <app-input
            v-model:value="confirm_password"
            custom-class="col-span-6"
            name="confirm_password"
            type="password"
            title="New password"
            :errors="errors" />
        </div>
      </div>
      <div class="px-4 pb-6 bg-gray-50 dark:bg-dark-theme-secondary text-right sm:px-6">
        <app-button title="Save" />
      </div>
    </div>
  </form>
</template>

<script>
  import axios from 'axios';
  import AppInput from '../../../globalComponents/AppInput.vue';
  import AppButton from '../../../globalComponents/AppButton.vue';

  export default {
    components: {
      AppInput,
      AppButton,
    },
    data() {
      return {
        old_password: '',
        new_password: '',
        confirm_password: '',
        errors: null,
      };
    },
    methods: {
      async updatePassword() {
        try {
          await axios({
            method: 'POST',
            url: 'change-password',
            data: {
              old_password: this.old_password,
              new_password: this.new_password,
              confirm_password: this.confirm_password,
            },
          });

          await this.$store.dispatch('setNotification', {
            title: 'Successfully updated!',
            message: 'The password has been updated',
          });
        } catch (error) {
          this.errors = JSON.parse(error.request.response);
        }
      },
    },
  };
</script>
