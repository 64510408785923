<template>
  <app-notification />
  <div class="home">
    <div class="h-screen flex overflow-hidden bg-gray-100 dark:bg-dark-theme-primary">
      <app-sidebar />
      <div class="flex flex-col flex-1">
        <app-topbar />
        <main class="flex-1 relative z-0 overflow-y-auto focus:outline-none">
          <app-overlay>
            <Messages />
          </app-overlay>
          <router-view />
        </main>
      </div>
    </div>
  </div>
</template>

<script>
  import AppNotification from '../../globalComponents/AppNotification.vue';
  import AppSidebar from '../../globalComponents/AppSidebar.vue';
  import AppTopbar from '../../globalComponents/AppTopbar.vue';
  import AppOverlay from '../../globalComponents/AppOverlay.vue';
  import Messages from '../MessageCenter/components/Messages.vue';

  export default {
    components: {
      AppNotification,
      AppSidebar,
      AppTopbar,
      AppOverlay,
      Messages,
    },
    async created() {
      if (!localStorage.getItem('theme') || localStorage.getItem('theme') === 'dark') {
        await this.$store.dispatch('switchTheme', 'dark');
      } else {
        await this.$store.dispatch('switchTheme', 'light');
      }

      if (localStorage.getItem('token')) {
        await this.$store.dispatch('fetchUser', { token: localStorage.getItem('token') });
      }
    },
  };
</script>
