<template>
  <div :class="customClass">
    <label
      :for="name"
      class="block text-sm font-medium text-gray-700 dark:text-gray-300">
      {{ title ? title : pascalCase(name) }}
    </label>
    <div
      v-click-outside="closeDropdown"
      class="mt-1 relative"
      :class="{ 'flex' : phone}">
      <div
        v-if="phone"
        class="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 dark:bg-gray-600 dark:text-white text-gray-500 sm:text-sm cursor-pointer"
        @click="openDropdown">
        <span>
          +{{ selectedCode }}
        </span>
        <span>
          <chevron-down-icon class="h-5 w-5" />
        </span>
      </div>
      <transition
        enter-active-class="transition ease-out duration-3000"
        enter-from-class="opacity-0 -translate-y-1"
        enter-to-class="opacity-100 translate-y-0"
        leave-active-class="transition ease-in duration-3000"
        leave-from-class="opacity-100 translate-y-0"
        leave-to-class="opacity-0 -translate-y-1">
        <div
          v-if="toggleDropdown"
          class="absolute z-10 flex flex-col bg-gray-100 dark:bg-gray-900 dark:text-gray-200 border border-gray-300 dark:border-gray-900 text-gray-500 text-center sm:text-sm"
          style="top: 38px; width: 69px">
          <span
            v-for="(code, index) in countryCodes"
            :key="index"
            class="py-1 cursor-pointer"
            :class="{ 'bg-gray-200 dark:bg-gray-800' : index % 2 === 0 }"
            @click="selectCode(code)">
            {{ code }}
          </span>
        </div>
      </transition>
      <input
        :id="name"
        :value="value"
        :name="name"
        :type="type"
        :autocomplete="name"
        class="appearance-none dark:text-gray-200 dark:bg-dark-theme-secondary block w-full px-3 py-2 border border-gray-300 dark:divide-dark-theme-primary shadow-sm placeholder-gray-400 focus:outline-none focus:ring-theme-blue focus:border-theme-blue dark:focus:ring-dark-theme-tertiary dark:focus:border-dark-theme-tertiary text-sm"
        :class="[ phone ? 'flex-1 min-w-0 block rounded:none rounded-r-md' : 'rounded-md']"
        @input="event => $emit('update:value', event.target.value)">
      <div
        v-if="errors && errors[name]"
        class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
        <ExclamationCircleIcon
          class="h-5 w-5 text-red-500"
          aria-hidden="true" />
      </div>
    </div>
    <p
      v-if="errors && errors[name]"
      id="password-error"
      class="text-sm text-red-600">
      {{ errors[name][0] }}
    </p>
  </div>
</template>

<script>
  import { ExclamationCircleIcon, ChevronDownIcon } from '@heroicons/vue/solid';
  import * as DemoApi from '../api/demo';

  export default {
    components: {
      ExclamationCircleIcon,
      ChevronDownIcon,
    },
    props: {
      title: {
        type: String,
        default: null,
      },
      name: {
        type: String,
        required: true,
      },
      value: {
        type: String,
        required: true,
      },
      type: {
        type: String,
        default: 'text',
      },
      errors: {
        type: [Array, Object],
        default: null,
      },
      phone: {
        type: Boolean,
        default: false,
      },
      customClass: {
        type: String,
        default: null,
      },
    },
    emits: ['update:value', 'update:code'],
    data() {
      return {
        selectedCode: 31,
        countryCodes: [],
        toggleDropdown: false,
      };
    },
    created() {
      this.setCodes();
    },
    methods: {
      pascalCase(str) {
        return `${str}`
          .replace(new RegExp(/[-_]+/, 'g'), ' ')
          .replace(new RegExp(/[^\w\s]/, 'g'), '')
          .replace(
            new RegExp(/\s+(.)(\w*)/, 'g'),
            ($1, $2, $3) => `${$2.toUpperCase() + $3.toLowerCase()}`,
          )
          .replace(new RegExp(/\w/), s => s.toUpperCase());
      },
      openDropdown() {
        this.toggleDropdown = !this.toggleDropdown;
      },
      closeDropdown() {
        this.toggleDropdown = false;
      },
      async setCodes() {
        const response = await DemoApi.getCountryNumbers();

        response.data.forEach(item => {
          this.countryCodes.push(item.country_code);
        });
      },
      async selectCode(code) {
        this.selectedCode = code;
        this.$emit('update:code', Number(code));
        this.closeDropdown();
      },
    },
  };
</script>
