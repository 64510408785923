<template>
  <div
    v-click-outside="closeDropdown"
    class="relative">
    <div class="sticky top-0 z-10 flex-shrink-0 flex h-16 bg-white dark:bg-dark-theme-secondary shadow">
      <div class="flex-1 flex justify-between">
        <button
          type="button"
          class="h-full w-12 md:hidden inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none"
          @click="$store.dispatch('sidebarOpen', true)">
          <span class="sr-only">Open sidebar</span>
          <MenuIcon
            class="h-6 w-6"
            aria-hidden="true" />
        </button>
        <div class="flex-1 flex">
          <form
            v-if="funds.length > 1"
            class="w-full flex md:ml-0"
            @submit.prevent="selectFund">
            <label
              for="search-field"
              class="sr-only">Search</label>
            <div class="relative w-full text-gray-400 focus-within:text-gray-600">
              <div class="absolute inset-y-0 left-2 flex items-center pointer-events-none" >
                <SearchIcon
                  class="h-5 w-5"
                  aria-hidden="true" />
              </div>
              <input
                id="search-field"
                ref="search"
                v-model="search"
                class="block w-full h-full pl-8 pr-3 py-2 dark:bg-dark-theme-secondary border-transparent text-gray-900 dark:text-gray-200 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent text-sm"
                placeholder="Search Fund"
                type="search"
                name="search"
                autocomplete="off"
                @focus="toggleDropdown = true">
            </div>
          </form>
        </div>
        <div class="ml-4 mr-4 flex items-center md:ml-6">
          <button
            type="button"
            class="bg-white relative dark:bg-dark-theme-secondary p-1 rounded-full text-gray-500 dark:text-gray-300 hover:text-gray-500 focus:outline-none"
            @click.prevent="openNavOverlay">
            <span class="sr-only">View notifications</span>
            <span
              v-if="$store.getters.newMessageNotifications"
              class="notification-badge bg-red-500">
              {{ $store.getters.newMessageNotifications }}
            </span>
            <BellIcon
              class="h-6 w-6"
              aria-hidden="true" />
          </button>

          <Menu
            as="div"
            class="ml-3 relative">
            <div>
              <MenuButton
                v-if="$store.getters.user"
                class="max-w-xs bg-white dark:bg-dark-theme-secondary dark:text-gray-200 flex items-center text-sm rounded-full focus:outline-none">
                <span class="sr-only">Open user menu</span>
                <span>{{ $store.getters.user.email }}</span>
                <ChevronDownIcon
                  class="h-3 w-3 ml-2 text-gray-400"
                  aria-hidden="true" />
              </MenuButton>
            </div>
            <transition
              enter-active-class="transition ease-out duration-100"
              enter-from-class="transform opacity-0 scale-95"
              enter-to-class="transform opacity-100 scale-100"
              leave-active-class="transition ease-in duration-75"
              leave-from-class="transform opacity-100 scale-100"
              leave-to-class="transform opacity-0 scale-95">
              <MenuItems class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white dark:bg-dark-theme-secondary ring-1 ring-black ring-opacity-5 focus:outline-none">
                <MenuItem
                  v-for="item in userNavigation"
                  :key="item.name"
                  v-slot="{ active }">
                  <div v-if="item.name === 'Logout'">
                    <a
                      href="#"
                      :class="[active ? 'bg-gray-100 dark:bg-gray-700' : '', 'block px-4 py-2 text-sm text-gray-700 dark:text-gray-300']"
                      @click.prevent="logout">Logout</a>
                  </div>
                  <div v-else>
                    <router-link
                      :to="{ name: item.href }"
                      :class="[active ? 'bg-gray-100 dark:bg-gray-700' : '', 'block px-4 py-2 text-sm text-gray-700 dark:text-gray-300']">
                      {{ item.name }}
                    </router-link>
                  </div>
                </MenuItem>
              </MenuItems>
            </transition>
          </Menu>
        </div>
      </div>
    </div>

    <transition
      enter-active-class="transition ease-out duration-3000"
      enter-from-class="opacity-0 -translate-y-1"
      enter-to-class="opacity-100 translate-y-0"
      leave-active-class="transition ease-in duration-3000"
      leave-from-class="opacity-100 translate-y-0"
      leave-to-class="opacity-0 -translate-y-1">
      <div
        v-if="toggleDropdown"
        class="absolute z-10 inset-x-0 transform shadow-lg"
        style="top: 65px">
        <div class="relative bg-white dark:bg-dark-theme-secondary">
          <ul
            role="list"
            class="divide-y divide-gray-200 dark:divide-gray-900">
            <li
              v-for="fund in filteredList"
              :key="fund.id"
              class="flex">
              <div class="pl-12 py-4 hover:bg-gray-100 dark:hover:bg-dark-theme-primary w-full cursor-pointer">
                <p
                  class="text-sm font-medium text-gray-900 dark:text-gray-300"
                  @click.prevent="selectFund(fund.id)">
                  {{ fund.name }}
                </p>
              </div>
            </li>
            <li
              v-if="!filteredList.length"
              class="flex">
              <div class="pl-12 py-4 hover:bg-gray-100 hover:bg-dark-theme-primary w-full cursor-pointer">
                <p class="text-sm font-medium text-gray-900 dark:text-gray-300">
                  No results found...
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
  import {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
  } from '@headlessui/vue';
  import {
    BellIcon,
    ChevronDownIcon,
    SearchIcon,
    MenuIcon,
  } from '@heroicons/vue/outline';

  import * as Api from '../api/funds';

  export default {
    components: {
      Menu,
      MenuButton,
      MenuItem,
      MenuItems,
      MenuIcon,
      BellIcon,
      ChevronDownIcon,
      SearchIcon,
    },
    data() {
      return {
        userNavigation: [
          { name: 'Settings', href: 'Settings' },
          { name: 'Logout', href: '#' },
        ],
        funds: [],
        search: '',
        toggleDropdown: false,
      };
    },
    computed: {
      filteredList() {
        return this.funds.filter(fund => {
          return fund.name.toLowerCase().includes(this.search.toLowerCase());
        });
      },
    },
    created() {
      this.getAllFunds();
    },
    methods: {
      closeDropdown() {
        this.toggleDropdown = false;
      },
      openNavOverlay() {
        this.$store.dispatch('showMessagesSidebar', true);
      },
      async selectFund(id) {
        await this.$store.dispatch('setSelectedFund', id);
        this.search = '';
        this.toggleDropdown = false;
      },
      async getAllFunds() {
        try {
          const response = await Api.getAllFunds();
          this.funds = response.data;
        } catch (error) {
          console.log(error);
        }
      },
      async logout() {
        await this.$store.dispatch('logout');
        await this.$router.push({ name: 'Login' });
      },
    },
  };
</script>
