import axios from 'axios';
import themeColors from '../helpers/messageCenterColorSet';
import * as AuthApi from '../api/auth';
import * as FundsApi from '../api/funds';
import * as MessagesApi from '../api/messages';
import * as NotificationApi from '../api/notifications';

export const login = async ({ commit, dispatch }, user) => {
  commit('auth_request');

  const response = await AuthApi.loginUser(user);

  // try {
  //   const coinsLogin = await AuthApi.loginCoins();
  //   localStorage.setItem('coins_token', coinsLogin.data.accessToken);
  // } catch (error) {
  //   console.log('error');
  // }

  if (response.request.status === 401 || response.request.status === 422) {
    throw (response.request.response);
  }

  const token = response.data.token;

  localStorage.setItem('token', token);
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

  await dispatch('fetchUser', { token, getFundsFirst: true });
};

export const fetchUser = async ({ commit, dispatch }, payload) => {
  try {
    const response = await AuthApi.getMe();

    // Async order because of the login screen bug
    if (payload.getFundsFirst) {
      await dispatch('setGlobalFund');
      await commit('auth_success', { user: response.data, token: payload.token });
    } else {
      await commit('auth_success', { user: response.data, token: payload.token });
      await dispatch('setGlobalFund');
    }
  } catch (error) {
    const result = error.networkError.result;
    if (result.code === 401 && result.message === 'Expired JWT Token') {
      await dispatch('logout');
    }
  }
};

export const setGlobalFund = async ({ commit, dispatch }, selectedFund) => {
  try {
    const getFunds = await FundsApi.getAllFunds();
    const selected = selectedFund || getFunds.data[0].id;
    const getFund = await FundsApi.getFund(selected);
    const getFundNav = await FundsApi.getNavByFundId(selected);
    const getTransactions = await FundsApi.getTransactionsById(selected);
    getFundNav.data.reversed_keys = Object.keys(getFundNav.data.per_year_per_month).slice().reverse();

    if (!selectedFund) {
      commit('set_selected_fund', getFunds.data[0].id);
    }

    commit('set_fund_nav', getFundNav.data);
    commit('set_fund', getFund.data);
    commit('set_transactions', getTransactions.data);
    await dispatch('getMessages');
  } catch (error) {
    console.log(error);
  }
};
export const getMessages = async ({ commit, dispatch, getters }) => {
  try {
    const response = await MessagesApi.getAllMessages(getters.selectedFund);

    response.data.forEach(item => {
      if (item.read.notification_shown === 0) {
        dispatch('setNotification', {
          id: item.id,
          type: item.type.code,
          sub_type: item.sub_type ? item.sub_type.code : null,
          style: 'inbox',
          title: item.title,
          message: item.intro,
          colorSet: themeColors(item),
          setRead: true,
          stayAlive: true,
        });
      }
    });

    commit('number_new_message', response.data.filter(item => item.read.read === 0).length);
    commit('set_messages', response.data);
  } catch (error) {
    console.log(error);
  }
};

export const showMessagesSidebar = async ({ commit }, bool) => {
  commit('show_messages_sidebar', bool);
};

export const logout = async ({ commit }) => {
  localStorage.removeItem('token');
  localStorage.removeItem('coins_token');
  delete axios.defaults.headers.common.Authorization;
  commit('logout');
};

export const setNotification = async ({ commit, dispatch, getters }, notification) => {
  let id;
  let duration;
  notification.id ? id = notification.id : id = Math.floor(Math.random() * 100);
  notification.duration ? duration = notification.duration : duration = 3000;
  commit('set_notification', { ...notification, id, show: true });

  const currentIndex = getters.notifications.indexOf(id);

  if (!notification.stayAlive) {
    setTimeout(() => {
      dispatch('removeNotification', { id: currentIndex });
    }, duration);
  }
};

export const removeNotification = async ({ commit }, payload) => {
  if (payload.payload && payload.payload.setRead) {
    try {
      await NotificationApi.setNotification(payload.payload.id);
    } catch (error) {
      console.log(error);
    }
  }

  commit('remove_notification', payload.id);
};

export const switchTheme = async ({ commit }, currentTheme) => {
  if (currentTheme === 'dark') {
    document.documentElement.classList.add('dark');
    localStorage.setItem('theme', 'dark');
  } else {
    document.documentElement.classList.remove('dark');
    localStorage.setItem('theme', 'light');
  }

  commit('switch_theme', currentTheme);
};

export const setSelectedFund = async ({ commit, dispatch }, selectedFund) => {
  commit('set_selected_fund', selectedFund);

  await dispatch('setGlobalFund', selectedFund);
};

export const setMessageToReadById = async ({ commit }, id) => {
  commit('set_message_to_read_by_id', id);
};

export const sidebarOpen = async ({ commit }, bool) => {
  commit('set_sidebar_value', bool);
};
