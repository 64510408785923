export default {
  isLoggedIn: state => !!state.token,
  coinsToken: state => state.coinsToken,
  authStatus: state => state.status,
  user: state => state.user,
  selectedFund: state => state.selectedFund,
  fund: state => state.fund,
  fundNav: state => state.fundNav,
  messages: state => state.messages,
  sidebarOpen: state => state.sidebarOpen,
  newMessageNotifications: state => state.newMessageNotifications,
  showMessagesSidebar: state => state.showMessagesSidebar,
  transactions: state => state.transactions,
  notifications: state => state.notifications,
  theme: state => state.theme,
};
