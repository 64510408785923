import { createRouter, createWebHistory } from 'vue-router';
import Home from './home';
import store from '../store';
import beforeEach from './beforeEach';
import DashboardRoutes from '../modules/Dashboard/routes/index';
import SettingsRoutes from '../modules/Settings/routes/index';
import MessageCenter from '../modules/MessageCenter/routes/index';
import ContactRoutes from '../modules/Contact/routes/index';
import AuthRoutes from '../modules/Auth/routes/index';
import catchAllRoutes from './catchAllRoutes';

const routes = [
  Home,
  ...AuthRoutes,
  ...DashboardRoutes,
  ...SettingsRoutes,
  ...MessageCenter,
  ...ContactRoutes,
  ...catchAllRoutes,
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: 'active',
  linkExactActiveClass: 'active',
});

beforeEach(router, store);

export default router;
