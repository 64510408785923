import axios from 'axios';

export const loginUser = user => {
  return axios.post('login', user);
};

export const loginCoins = () => {
  return axios({
    method: 'post',
    baseURL: 'https://binancesnapshotscheduler.azurewebsites.net/',
    url: 'login',
    data: {
      username: 'Dev',
      password: 'U2A62ztat3',
    },
  });
};

export const getMe = () => {
  return axios.get('clients/me');
};
