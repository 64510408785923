import { createApp } from 'vue';
import axios from 'axios';
import App from './App.vue';
import globalMixin from './helpers/globalMixin';
import './registerServiceWorker';
import router from './router';
import store from './store';
import './index.css';

import ClickOutside from './directives/click-outside';
import './assets/tailwind.css';

if (process.env.NODE_ENV === 'production') {
  axios.defaults.baseURL = 'https://api.majorcapital.com/api';
} else {
  axios.defaults.baseURL = 'https://dev.api.majorcapital.com/api';
  // axios.defaults.baseURL = 'https://tradingdesk.api.test/api';
}

if (localStorage.getItem('token')) {
  axios.defaults.headers.common = { Authorization: `Bearer ${localStorage.getItem('token')}` };
}

axios.interceptors.response.use(undefined, async error => {
  if (error) {
    const originalRequest = error.config;
    // eslint-disable-next-line no-underscore-dangle
    if (error.response.status === 401 && !originalRequest._retry && localStorage.getItem('token') && error.request.response === 'Unauthorized.') {
      // eslint-disable-next-line no-underscore-dangle
      originalRequest._retry = true;
      await store.dispatch('logout');
      return router.push('/login');
    }
  }

  return Promise.reject(error);
});

const app = createApp(App).use(store).use(router);
app.mixin(globalMixin);
app.directive('click-outside', ClickOutside);
app.mount('#app');
