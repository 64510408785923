import axios from 'axios';

export const getAllFunds = () => {
  return axios.get('funds');
};

export const getFund = id => {
  return axios.get(`funds/get?id=${id}`);
};

export const getNavByFundId = fundId => {
  return axios.get(`funds/nav?fund_id=${fundId}`);
};

export const getFundDemoInformation = fundId => {
  return axios.get(`funds/${fundId}/investments`);
};

export const getTransactionsById = id => {
  return axios.get(`funds/transactions?fund_id=${id}`);
};
