<template>
  <div>
    <app-title :text="currentFund && currentFund.name ? currentFund.name : 'Loading...'" />
    <dl
      class="mt-5 grid grid-cols-1 rounded-lg bg-white dark:bg-dark-theme-secondary overflow-hidden shadow divide-y divide-gray-200 dark:divide-dark-theme-primary md:grid-cols-4 md:divide-y-0 md:divide-x">
      <Block
        title="Participations"
        :data="currentFund && currentFund.participations ? currentFund.participations : 0" />

      <Block
        title="Nav"
        :data="currentFund && currentFund.nav ? money(currentFund.nav.current_nav) : 0"
        :label="currentFund && currentFund.nav ? currentFund.nav.difference_in_percentage : 0" />

      <Block
        title="Value"
        :data="currentFund && currentFund.values ? money(currentFund.values.amount) : 0" />

      <Block
        title="Changes this months"
        :data="currentFund && currentFund.values ? money((currentFund.values.amount - currentFund.values.previous_amount)) : 0" />
    </dl>
  </div>
</template>

<script>
  import AppTitle from '../../../globalComponents/AppTitle.vue';
  import Block from './Block.vue';

  export default {
    components: {
      AppTitle,
      Block,
    },
    computed: {
      currentFund() {
        return this.$store.getters.fund;
      },
    },
  };
</script>
