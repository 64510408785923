import {
  MailIcon,
  PaperAirplaneIcon,
  ShieldCheckIcon,
  SpeakerphoneIcon,
} from '@heroicons/vue/outline';

const switchCase = item => {
  return item.sub_type ? item.sub_type.code : item.type.code;
};

const colorSet = [
  {
    name: 'message',
    textColor: 'text-gray-400',
    bgColor: 'bg-gray-400',
    stripe: 'bg-gray-200',
    borderColor: 'border-gray-400',
    icon: MailIcon,
  },
  {
    name: 'bugfix',
    textColor: 'text-red-400',
    bgColor: 'bg-red-400',
    stripe: 'bg-red-200',
    borderColor: 'border-red-400',
    icon: ShieldCheckIcon,
  },
  {
    name: 'feature',
    textColor: 'text-blue-400',
    bgColor: 'bg-blue-400',
    stripe: 'bg-blue-200',
    borderColor: 'border-blue-400',
    icon: SpeakerphoneIcon,
  },
  {
    name: 'update',
    textColor: 'text-yellow-400',
    bgColor: 'bg-yellow-400',
    stripe: 'bg-yellow-200',
    borderColor: 'border-yellow-400',
    icon: PaperAirplaneIcon,
  },
];

export default givenArray => {
  const selected = colorSet.filter(elem => elem.name === switchCase(givenArray));
  return selected[0];
};
