<template>
  <app-title text="History" />

  <app-table :header-data="tableHeaderData">
    <tbody v-if="transactions">
      <tr
        v-for="(data, index) in transactions"
        :key="index"
        :class="index % 2 === 0 ? 'bg-white dark:bg-dark-theme-secondary' : 'bg-gray-50 dark:bg-dark-theme-primary'">
        <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 flex items-center">
          <div :class="[data.type !== 'buy' ? 'bg-green-100 text-green-800' : 'bg-blue-100 text-blue-800', 'inline-flex items-baseline px-1.5 py-0.5 rounded-full text-sm font-medium mr-2']">
            <ArrowSmUpIcon
              v-if="data.type !== 'buy'"
              class="flex-shrink-0 self-center h-5 w-5 text-green-500"
              aria-hidden="true" />
            <ArrowSmDownIcon
              v-else
              class="flex-shrink-0 self-center h-5 w-5 text-blue-500"
              aria-hidden="true" />
          </div>
          <span class="dark:text-gray-300">
            {{ data.datetime }}
          </span>
        </td>
        <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-gray-300">
          {{ Number(data.participations).toFixed(2) }}
        </td>
        <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-gray-300">
          {{ money(data.value) }}
        </td>
      </tr>
    </tbody>
    <tbody v-else>
      <tr>
        <td
          class="p-3 text-gray-800 dark:text-gray-200 dark:bg-dark-theme-primary"
          colspan="99">
          No results...
        </td>
      </tr>
    </tbody>
  </app-table>
</template>

<script>
  import { ArrowSmDownIcon, ArrowSmUpIcon } from '@heroicons/vue/solid';
  import AppTitle from '../../../globalComponents/AppTitle.vue';
  import AppTable from '../../../globalComponents/AppTable.vue';

  export default {
    components: {
      ArrowSmDownIcon,
      ArrowSmUpIcon,
      AppTitle,
      AppTable,
    },
    props: {
      transactions: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        tableHeaderData: ['Date', 'Participations', 'Amount'],
      };
    },
  };
</script>
