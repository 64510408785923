<template>
  <app-title text="Monthly statistics" />

  <app-table :header-data="tableHeaderData" :styling-off="true">
    <tbody v-if="fundsData.per_year_per_month && !Array.isArray(fundsData.per_year_per_month)">
      <tr
        v-for="(index) in fundsData.reversed_keys"
        :key="index"
        :class="index % 2 === 0 ? 'bg-white dark:bg-dark-theme-secondary' : 'bg-gray-50 dark:bg-gray-700'">
        <td class="px-3 py-4 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-gray-200">
          {{ index }}
        </td>

        <td
          v-for="idx in 12"
          :key="idx"
          class="px-3 py-4 whitespace-nowrap text-sm font-medium"
          :class="[checkTextColor(fundsData.per_year_per_month[index][idx])]">
          <div v-if="fundsData.per_year_per_month[index][idx]">
            <span>
              {{ fundsData.per_year_per_month[index][idx]['change_last_month'] }}% <br>
            </span>
            <span class="text-xs">
              {{ money(fundsData.per_year_per_month[index][idx]['value']) }}
            </span>
          </div>
          <div v-else>
            <span>
              -
            </span>
          </div>
        </td>
      </tr>
    </tbody>
  </app-table>
</template>

<script>
  import AppTitle from '../../../globalComponents/AppTitle.vue';
  import AppTable from '../../../globalComponents/AppTable.vue';

  export default {
    components: {
      AppTitle,
      AppTable,
    },
    props: {
      fundsData: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        currentSort: 'name',
        currentSortDir: 'asc',
        tableHeaderData: ['Year', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      };
    },
    methods: {
      checkTextColor(option) {
        if (option && option.change_last_month > 0) {
          return 'text-green-500';
        } else if (option && option.change_last_month < 0) {
          return 'text-red-500';
        }

        return 'text-gray-500 dark:text-gray-200';
      },
    },
  };
</script>
