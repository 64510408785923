import Login from '../pages/Login.vue';
import Register from '../pages/Register.vue';

export default [
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/demo',
    name: 'Register',
    component: Register,
  },
];
