export default {
  status: '',
  token: localStorage.getItem('token') || '',
  user: null,
  fund: null,
  fundNav: null,
  selectedFund: null,
  messages: null,
  showMessagesSidebar: false,
  sidebarOpen: false,
  theme: 'dark',
  notifications: [],
};
