<template>
  <app-title text="Monthly percentage" />
  <div
    v-if="data.labels && options"
    class="mt-2 rounded-lg bg-white dark:bg-dark-theme-secondary overflow-hidden shadow divide-y divide-gray-200 p-5">
    <BarChart
      ref="barChart"
      :key="currentTheme"
      :chart-data="data"
      :options="options"
      :plugins="data.plugin" />
  </div>
</template>

<script>
  import { ref, onMounted } from 'vue';
  import { BarChart } from 'vue-chart-3';
  import ChartDataLabels from 'chartjs-plugin-datalabels';
  import { Chart, registerables } from 'chart.js';
  import { useStore } from 'vuex';
  import AppTitle from '../../../globalComponents/AppTitle.vue';
  import { plugins, themeLightOptions, themeDarkOptions } from '../../../helpers/chartThemeColorSet';

  Chart.register(...registerables);

  export default {
    name: 'App',
    components: {
      BarChart,
      AppTitle,
    },
    props: {
      fundsData: {
        type: Object,
        required: true,
      },
    },
    setup(props) {
      const store = useStore();

      const data = ref({
        labels: [],
        datasets: [
          {
            label: 'Monthly',
            data: [],
            datalabels: {
              color: [],
            },
            backgroundColor: [],
          },
        ],
        plugin: [ChartDataLabels],
      });

      let options = ref({});
      const barChart = ref();

      if (store.getters.theme === 'light' || !store.getters.theme) {
        options = { ...plugins, ...themeLightOptions };
      } else {
        options = { ...themeDarkOptions, ...plugins };
      }

      const chartDataHandler = async () => {
        const labels = [];
        const chartData = [];
        const bgColors = [];
        const labelColors = [];
        let labelColorDefault = {
          green: 'rgba(6, 84, 58, .8)',
          red: 'rgba(135, 18, 18, .8)',
        };
        let barColorDefault = {
          red: '239, 68, 68',
          green: '16, 185, 129',
        };

        if (store.getters.theme === 'dark' && store.getters.theme) {
          labelColorDefault = {
            green: 'rgba(255, 255, 255, .5)',
            red: 'rgba(255, 255, 255, .5)',
          };

          barColorDefault = {
            red: '239, 68, 68',
            green: '16, 185, 129',
          };
        }

        const gradientPositive = barChart.value.chartInstance.ctx.createLinearGradient(30, 20, 10, 450);
        const gradientNegative = barChart.value.chartInstance.ctx.createLinearGradient(30, 20, 10, 450);

        gradientPositive.addColorStop(0, `rgba(${barColorDefault.green}, 0.8)`);
        gradientPositive.addColorStop(1, `rgba(${barColorDefault.green}, 0)`);

        gradientNegative.addColorStop(0, `rgba(${barColorDefault.red}, 0.8)`);
        gradientNegative.addColorStop(1, `rgba(${barColorDefault.red}, 0)`);

        try {
          // TODO: REFACTOR WITH OVERVIEW WEEKLY STATISTICS
          const monthlyData = [];

          Object.values(props.fundsData.per_year_per_month).forEach(monthly => {
            Object.values(monthly).forEach(item => {
              if (item.month !== undefined || item.year !== undefined) {
                monthlyData.push({
                  label: `${item.month}-${item.year}`,
                  nav: item.nav,
                  percentage: item.change_last_month,
                });
              }
            });
          });

          monthlyData.forEach(item => {
            labels.push(item.label);
            chartData.push(item.percentage);

            if (item.percentage >= 0) {
              labelColors.push(labelColorDefault.green);
              bgColors.push(gradientPositive);
            } else {
              labelColors.push(labelColorDefault.red);
              bgColors.push(gradientNegative);
            }
          });

          data.value.labels = labels;
          data.value.datasets[0].data = chartData;
          data.value.datasets[0].backgroundColor = bgColors;
          data.value.datasets[0].datalabels.color = labelColors;
        } catch (error) {
          console.log(error);
        }
      };

      onMounted(() => {
        chartDataHandler();
      });

      return {
        barChart,
        data,
        options,
        chartDataHandler,
      };
    },
    computed: {
      currentTheme() {
        return this.$store.getters.theme;
      },
    },
    watch: {
      currentTheme() {
        this.checkTheme();
        this.chartDataHandler();
      },
    },
    methods: {
      checkTheme() {
        if (this.$store.getters.theme === 'light' || !this.$store.getters.theme) {
          this.options = { ...plugins, ...themeLightOptions };
        } else {
          this.options = { ...themeDarkOptions, ...plugins };
        }
      },
    },
  };
</script>
