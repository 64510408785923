<template>
  <div class="px-4">
    <DialogTitle class="text-lg font-medium text-gray-900 dark:text-gray-300">
      Messages
    </DialogTitle>
  </div>
  <div class="relative flex-1">
    <messages-list
      v-if="messages"
      :items="messages"
      :link-it="true" />
    <app-spinner v-if="!messages" />
  </div>
</template>

<script>
  import { DialogTitle } from '@headlessui/vue';
  import MessagesList from './MessagesList.vue';
  import AppSpinner from '../../../globalComponents/AppSpinner.vue';

  export default {
    components: {
      DialogTitle,
      MessagesList,
      AppSpinner,
    },
    computed: {
      messages() {
        return this.$store.getters.messages;
      },
    },
  };
</script>
