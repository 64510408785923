import Settings from '../pages/Index.vue';
import Page from '../../GeneralViews/Page.vue';

export default [
  {
    path: '/settings',
    component: Page,
    children: [
      {
        path: '',
        name: 'Settings',
        component: Settings,
      },
    ],
    meta: { requiresAuth: true },
  },
];
