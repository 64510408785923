<template>
  <div class="py-6">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
      <h1 class="text-2xl font-semibold text-gray-900 dark:text-dark-theme-tertiary">
        Dashboard
      </h1>
    </div>
    <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
      <div v-if="fundNavData && transactions && $store.getters.isLoggedIn">
        <overview-blocks />
        <overview-demo-chart v-if="$store.getters.fund.display_investments === 1 && $store.getters.fund" />
<!--        <overview-assets v-if="$store.getters.fund.display_assets === 1 && $store.getters.fund" />-->
        <overview-monthly-statistics
          :key="fundNavData"
          :funds-data="fundNavData" />
        <overview-nav
          :key="fundNavData"
          :funds-data="fundNavData" />
        <essential-info-table v-if="$store.getters.user.role === 'lead'" />
        <overview-weekly-statistics
          :key="fundNavData"
          :funds-data="fundNavData" />
        <overview-history
          :key="fundNavData"
          :transactions="transactions" />
      </div>
      <div v-else>
        <div style="top: 50%; left: 50%; position: absolute">
          <div
            class="animate-spin rounded-full h-5 w-5 border-2 border-gray-300"
            style="border-top-color: #207aff"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import OverviewBlocks from '../components/OverviewBlocks.vue';
  import OverviewNav from '../components/OverviewNav.vue';
  import OverviewWeeklyStatistics from '../components/OverviewWeeklyStatistics.vue';
  import OverviewMonthlyStatistics from '../components/OverviewMonthlyStatistics.vue';
  import OverviewHistory from '../components/OverviewHistory.vue';
  // import OverviewAssets from '../components/OverviewAssets.vue';
  import OverviewDemoChart from '../components/OverviewDemoChart.vue';
  import EssentialInfoTable from '../components/EssentialInfoTable.vue';

  export default {
    name: 'Home',
    components: {
      OverviewBlocks,
      // OverviewAssets,
      OverviewNav,
      OverviewDemoChart,
      OverviewWeeklyStatistics,
      OverviewMonthlyStatistics,
      OverviewHistory,
      EssentialInfoTable,
    },
    computed: {
      fundNavData() {
        return this.$store.getters.fundNav;
      },
      transactions() {
        return this.$store.getters.transactions;
      },
    },
  };
</script>
