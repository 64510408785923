<template>
  <ul
    role="list"
    class="border-b border-gray-200 dark:border-gray-900 divide-y divide-gray-200 dark:divide-gray-900">
    <li
      v-for="item in items"
      :key="item.id"
      :class="[item.read.read === 1 ? 'bg-white dark:bg-dark-theme-primary' : 'bg-gray-50 dark:bg-dark-theme-secondary']"
      class="relative py-5 px-6 hover:bg-gray-100 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-600 cursor-pointer"
      @click="selectMessage(item.id)">
      <div class="flex relative">
        <span
          class="absolute top-6 left-3 -ml-px h-full w-0.5"
          :class="colorSet(item).stripe"
          aria-hidden="true"></span>
        <div>
          <span
            class="h-6 w-6 rounded-full flex items-center justify-center ring-8 ring-transparent"
            :class="colorSet(item).bgColor">
            <component
              :is="colorSet(item).icon"
              class="h-4 w-4 text-white"
              aria-hidden="true" />
          </span>
        </div>
        <div class="pl-3 w-full">
          <div class="flex justify-between space-x-3">
            <div class="min-w-0 flex-1">
              <a
                :href="item.href"
                class="block focus:outline-none">
                <span
                  class="absolute inset-0"
                  aria-hidden="true"></span>
                <p class="text-sm font-medium text-gray-900 dark:text-dark-theme-tertiary truncate">
                  {{ item.title }}
                </p>
              </a>
            </div>
            <time
              :datetime="item.publish_at"
              class="flex-shrink-0 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">
              {{ item.publish_at }}
            </time>
          </div>
          <div class="mt-1">
            <p class="line-clamp-2 text-sm text-gray-600 dark:text-gray-300">
              {{ item.intro }}
            </p>
          </div>
        </div>
      </div>
    </li>
    <li
      v-if="items && !items.length"
      class="relative bg-white dark:bg-dark-theme-secondary py-5 px-6">
      <div class="flex justify-between space-x-3">
        <div class="min-w-0 flex-1">
          <p class="text-sm font-medium dark:text-gray-300">
            No results...
          </p>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
  import themeColors from '../../../helpers/messageCenterColorSet';

  export default {
    props: {
      items: {
        type: [Object, Array],
        required: true,
      },
      linkIt: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['selectMessage'],
    methods: {
      colorSet(item) {
        return themeColors(item);
      },
      async selectMessage(id) {
        await this.$store.dispatch('setMessageToReadById', id);

        if (this.linkIt) {
          this.$store.dispatch('showMessagesSidebar', false);
          await this.$router.push({ name: 'Message Center', params: { id } });
        } else {
          this.$parent.$emit('selectMessage', id);
        }
      },
    },
  };
</script>
