<template>
  <nav
    aria-label="Message list"
    class="min-h-0 flex-1 overflow-y-auto">
    <messages-list :items="sortedItems" />
  </nav>
</template>

<script>
  import MessagesList from './MessagesList.vue';

  export default {
    components: {
      MessagesList,
    },
    props: {
      sortedItems: {
        type: [Array, Object],
        required: true,
      },
    },
  };
</script>
