<template>
  <div>
    <button
      type="submit"
      :class="{'w-full flex': fullWidth}"
      class="justify-center py-2 px-4 border border-transparent rounded-md shadow-sm mt-6 font-medium text-white dark:text-dark-theme-primary bg-theme-blue dark:bg-dark-theme-tertiary hover:bg-indigo-700 focus:outline-none">
      <span
        v-if="loading"
        class="animate-spin block rounded-full h-5 w-5 border-2 border-gray-300"
        style="border-top-color: #555555">
      </span>
      <span v-else>
        {{ title }}
      </span>
    </button>
  </div>
</template>

<script>
  export default {
    props: {
      loading: {
        type: Boolean,
        default: false,
      },
      title: {
        type: String,
        required: true,
      },
      fullWidth: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>
